import React, { useEffect, useRef, useState } from 'react';
import useDeepCompareEffect from '../../services/General/useDeepCompareEffect';

interface GoogleMapProps extends google.maps.MapOptions {
    id: string;
    onClick?: (e: google.maps.MapMouseEvent) => void; // Add onClick prop
}

export const GoogleMap: React.FC<GoogleMapProps> = ({ children, id, onClick, ...options }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();

    useEffect(() => {
        if (window.google && ref.current && !map) {
            const mapInstance = new window.google.maps.Map(
                document.querySelector(`#${id}`) as HTMLElement,
                options
            );

            if (onClick) {
                mapInstance.addListener('click', onClick); // Attach onClick handler
            }

            setMap(mapInstance);
        }
    }, [ref, map, onClick]);

    useDeepCompareEffect(() => {
        if (map) {
            map.setOptions(options);
        }
    }, [map, options]);

    return (
        <div ref={ref} style={{ width: "100%", height: "100%" }} id={id}>
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, { map });
                }
            })}
        </div>
    );
}


export const EmergencyModeTicker = () => (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: 'red',
    color: 'white',
    textAlign: 'center',
    padding: '10px',
    zIndex: 1000 // Ensure it overlays on top of other components
  }}>
    Emergency Override Mode Active - Collision detection bypassed!
  </div>
);

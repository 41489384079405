import { useState, useEffect } from "react";
import { Marker } from "../../core/Maps/Marker";
import { IStopInfo, StopData } from './StopTravelBody';
import { MapContainer } from '../../core/Maps/MapContainer';
import { RobotTrackingMarker } from "../../core/Maps/RobotTrackingMarker";

interface Props {
    missionInfo: IStopInfo;
    activeIndex: string;
    onMarkerClick: (stop: StopData) => void;
}

/**
 * Generates stops and polylines for the map component. Stops and lines are colored based on their status (selected or not, visited or not).
 */
export const StopTravelMap = ({ missionInfo, activeIndex, onMarkerClick }: Props) => {
    const [stops, setStops] = useState<IStopInfo>(missionInfo);
    const [stopList, setStopList] = useState<JSX.Element[]>([]);
    const [center, setCenter] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 });
    const [zoom, setZoom] = useState(5); // Default zoom level

    useEffect(() => {
        setStops(missionInfo);

        const newBounds = new google.maps.LatLngBounds();
        
        // Filter stops by excluding the stop with the highest number in each zone
        const zoneHighestStop: Record<string, number> = {};

        missionInfo.stops.forEach((item) => {
            const zoneNumber = item.zone.substring(4);
            const stopNumber = parseInt(item.stop.substring(4), 10);

            if (!zoneHighestStop[zoneNumber] || stopNumber > zoneHighestStop[zoneNumber]) {
                zoneHighestStop[zoneNumber] = stopNumber;
            }
        });

        const filteredStops = missionInfo.stops.filter((item) => {
            const zoneNumber = item.zone.substring(4);
            const stopNumber = parseInt(item.stop.substring(4), 10);
            const isHome = (zoneNumber === '1') && (stopNumber === 1);
            const isStop1 = (stopNumber === 1 && zoneNumber!=='1');
            const test = (isHome || !isStop1) && stopNumber !== zoneHighestStop[zoneNumber] && zoneNumber !== '0';
            return test;
        });

        const newStopList = filteredStops.map((item) => {
            const location = new google.maps.LatLng(item.gps[0], item.gps[1]);
            newBounds.extend(location);
            if (item.zone !== 'zone0' && item.stop !== 'stop1'){
                return (
                    <Marker
                        key={`${item.zone}/${item.stop}`}
                        position={location}
                        onClick={() => onMarkerClick(item)}
                        label={{
                            text: `${item.zone.substring(4)}-${item.stop.substring(4)}`,
                            color: '#FFFFFF',
                            fontSize: '11px',
                            fontWeight: 'bold',
                        }}
                    />
                );
            }else{
                return (
                    <Marker
                        key={`${item.zone}/${item.stop}`}
                        position={location}
                        onClick={() => onMarkerClick(item)}
                        label={{
                            text: 'Home',
                            color: '#FFFFFF',
                            fontSize: '10px',
                            fontWeight: 'bold',
                            className: 'map-marker-label'
                        }} 
                    />
                );
            }
        });

        setStopList(newStopList);

        setCenter({
            lat: newBounds.getCenter().lat(),
            lng: newBounds.getCenter().lng(),
        });
        setZoom(15);

    }, [missionInfo, onMarkerClick]);

    if (!stops || stops.stops.length === 0) {
        console.log(activeIndex);
        return (
            <MapContainer id="path-planning-map" zoom={zoom} center={center}>
                <RobotTrackingMarker title="Robot" />
            </MapContainer>
        );
    }

    return (
        <MapContainer id="path-planning-map" zoom={zoom} center={center}>
            {stopList}
            <RobotTrackingMarker title="Robot" />
        </MapContainer>
    );
};

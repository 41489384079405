import { FC, useState, useEffect } from 'react';
import WidgetCard from './WidgetCard';
import { MapContainer } from '../Maps/MapContainer';
import { Subscriber } from '../../services/Ros/Subscriber';
import { GPS_TOPIC } from '../../constants/topics';
import { RobotTrackingMarker } from '../Maps/RobotTrackingMarker';
import { useScreenSize } from '../../services/General/ScreenResolutionProvider';
import { StopMarkerList } from '../Maps/StopMarkerProvider';
import { MAP_CENTER } from '../../constants/constants';

interface MapWidgetProps {
    position?: 'widget-top-left' | 'widget-top-right' | 'widget-bottom-left' | 'widget-bottom-right' | string;
}

export const MapWidget: FC<MapWidgetProps> = ({ position = 'widget-bottom-right' }) => {
    const { width } = useScreenSize();
    const [showMarker, setShowMarker] = useState(true);
    let local = position;

    if (width <= 1024) {
        local = 'widget-bottom-right';
    }

    const handleMapClick = (e: google.maps.MapMouseEvent) => {
        setShowMarker((prevShowMarker) => !prevShowMarker);
        console.log('Map clicked at: ', e.latLng?.toString());
    };

    useEffect(() => {
        console.log('MapWidget rendered');
    }, []);

    return (
        <WidgetCard position={local}>
            <WidgetCard.Header>
                Map 
            </WidgetCard.Header>
            <WidgetCard.Body>
                <Subscriber name={GPS_TOPIC.name} type={GPS_TOPIC.type} rate={GPS_TOPIC.throttle}>
                    <MapContainer
                        id="main-dash-map"
                        zoom={18}
                        center={{ lat: MAP_CENTER[0], lng: MAP_CENTER[1] }}
                        onClick={handleMapClick} // Add onClick handler here
                    >
                        <StopMarkerList/>
                        {showMarker && <RobotTrackingMarker title="Robot" />}
                        
                    </MapContainer>
                </Subscriber>
            </WidgetCard.Body>
        </WidgetCard>
    );
};

export default MapWidget;

import { useEffect, useRef, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faPlug } from '@fortawesome/free-solid-svg-icons';
import { useToggle } from '../../services/General/useToggle';
import { StopTravelBody } from './StopTravelBody';
import { useConnection } from '../../services/Ros/Connection';
import { ConnectionMessage } from '../../core/Messages/ConnectionMessage';
import { useDrivingMethod, DrivingTypes } from '../../services/Ros/Driving/DrivingProvider';
import { IStopInfo } from './StopTravelBody';
import { Subscriber } from '../../services/Ros/Subscriber';
import { GPS_TOPIC } from '../../constants/topics';
import '../../css/StreamSelector.css';  // Import the CSS file
import '../../css/path-planning.css'; 
import { useObserver } from '../../utilities/http/routing/ObserverContext';


interface StopTravelOverlayProps {
    isManualModeActive: boolean;
}


export const StopTravelOverlay = ({ isManualModeActive }: StopTravelOverlayProps)=> {
    const [show, setShow] = useToggle(false);
    const { control, setDisabled } = useDrivingMethod();
    const drivingMethod = useRef<DrivingTypes>(control);
    const connection = useConnection();
    const { isObserver } = useObserver();

    const [initialActiveIndex, setInitialActiveIndex] = useState<string>('');
    const [initialMissionInfo, setInitialMissionInfo] = useState<IStopInfo>({ stops: [] });
    const [initialLaunched, setInitialLaunched] = useState<boolean>(false);

    useEffect(() => {
        if (show) {
            drivingMethod.current = control;
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [show]);

    useEffect(() => {
        console.log(initialActiveIndex)
    }, [initialActiveIndex]);

    const render = () => {
        if (!connection) {
            return <ConnectionMessage icon={faPlug} text='Robot Not Connected' colour='dark' />;
        } else {
            return (
                <StopTravelBody 
                    initialActiveIndex={initialActiveIndex}
                    initialMissionInfo={initialMissionInfo}
                    initialLaunched={initialLaunched}
                    setInitialActiveIndex={setInitialActiveIndex}
                    setInitialMissionInfo={setInitialMissionInfo}
                    setInitialLaunched={setInitialLaunched}
                />
            );
        }
    }

    return (
        <>
            <Button
                variant='info'
                size="lg"
                onClick={setShow}
                className={show ? "selected" : ""}
                disabled={isManualModeActive || isObserver}
            >
                <FontAwesomeIcon icon={faLocationArrow} inverse={true} size="lg" />
            </Button>
            <Modal 
                fullscreen='lg-down' 
                className='path-planning-modal' 
                dialogClassName='modal-90w overflow-hidden' 
                size='xl' 
                centered 
                show={show} 
                onHide={setShow} >
                <Modal.Header closeButton>
                    <Modal.Title className='text-dark'></Modal.Title>
                </Modal.Header>
                <Modal.Body className='ps-0 pb-0 pt-0'>
                    <Subscriber name={GPS_TOPIC.name} type={GPS_TOPIC.type} rate={GPS_TOPIC.throttle}>
                        {render()}
                    </Subscriber>
                </Modal.Body>
            </Modal>
        </>
    );
}

import { MouseEventHandler, useEffect, useState } from 'react';
import { Button, ProgressBar, Spinner, Stack } from 'react-bootstrap';
import { MissionPlanSelect } from './MissionPlanSelect';
import { Stopwatch } from '../../core/Timers/Stopwatch';
import { useObserver } from '../../utilities/http/routing/ObserverContext';

type TimeRunType = string | null | undefined;

interface Props {
    progress : number;
    running : boolean;
    timeRun : TimeRunType;
    activeIndex : number;
    onSelect : (id : number) => void;       //Callback to handle the selection of a mission plan.
    onRun : MouseEventHandler;              //Callback to run to run the mission plan.
    onSave : MouseEventHandler;                    //Callback to run to let the main component know the data has been saved.
}

//@ts-ignore
const MissionStatusUpdater = ({running,missionId, startTime} : {running : boolean,missionId:number, startTime : TimeRunType}) => {

    const text = running === true ? "Running" : "Idle";

    return (
        <div className='mission-status-updates mb-3 ms-3 p-2 text-light text-center' style={{backgroundColor : running === true ? "#28A745" : "#999999"}}>
            <Stack direction='vertical'>
                <span className='mb-2 d-flex align-items-center'>{text} {running === true && <Spinner animation="border" size="sm" className="ms-auto"/>} </span>
                <Stopwatch running={running} missionId={missionId} startTime={startTime}/>

            </Stack>
        </div>
    );
}

//@ts-ignore
export const MissionPlanningControlBanner = ({ progress, running, timeRun, activeIndex, onSelect, onRun, onSave } : Props) => {

    const [ runEnabled, setRunEnabled ] = useState<boolean>(false);
    const { isObserver } = useObserver();

    const [selectedMissionId, setSelectedMissionId] = useState<number | null>(null); // State to store the selected mission plan ID

    const handleSelect = (id: number) => {
        setSelectedMissionId(id);
        onSelect(id); // Call the onSelect function passed from the parent component
    };

    
    useEffect(() => {
        
        console.log(selectedMissionId)
        if (selectedMissionId !== -1){
            setRunEnabled(true)
        }else{
            setRunEnabled(false)
        }
        

    }, [selectedMissionId]);


    useEffect(() => {
        
        setSelectedMissionId(activeIndex);
        onSelect(activeIndex); // Call the onSelect function passed from the parent component
        

    }, [activeIndex]);

    return (
        <div className='mission-planning-banner p-3 mb-4 bg-white rounded' >
            <span className='text-dark h5'>Mission Plans</span>
            <MissionPlanSelect onSelect={handleSelect} activeIndex={activeIndex}/>
            <MissionStatusUpdater running={running} missionId={activeIndex} startTime={timeRun}/>
            { running && <div className='mission-info mb-3 ps-3 pe-3'>
                <ProgressBar animated now={progress} variant="success" id="mission-progress" className='mb-3'/>
            </div>}
            <div className='d-flex'>
                <Button variant="primary" onClick={onRun} className="flex-fill me-2" disabled={!runEnabled || running || isObserver}>
                    Run
                </Button>
                <Button variant="danger" onClick={onSave} className="flex-fill" disabled={running || true}>
                    Remove
                </Button>
            </div>

        </div>
    );
}
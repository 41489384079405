import { VFC, useRef } from 'react';
import { useScreenSize } from '../../services/General/ScreenResolutionProvider';
import '../../css/progressbars.css';

interface CircleProgressProps{
    progress : number | null | undefined;
    circleOneStroke : string;
    circleTwoStroke : string;
    strokeWidth : number;
    className : string;
}

export const CircleProgress: VFC<CircleProgressProps> = ({ progress, circleOneStroke, circleTwoStroke, strokeWidth, className }) => {

    const { width } = useScreenSize();

    let size = width <= 1024 ? 20 : 35;
    strokeWidth = width <= 1024 ? 2 : strokeWidth;

    const circleRef = useRef<SVGCircleElement | null>(null);
    const centre:number = size / 2;
    const radius:number = size / 2 - strokeWidth / 2;
    const circumference:number = 2 * Math.PI * radius;

    if(!progress || progress === null || progress === undefined){
        progress = 0;
    }

    const offset = ((100 - progress) / 100) * circumference;

    return(
        <svg className={`circle-progress ${className}`} width={size} height={size}>
            <circle
                className="svg-circle-progress-bg"
                stroke={circleOneStroke}
                cx={centre}
                cy={centre}
                r={radius}
                strokeWidth={strokeWidth}
            />
            <circle
                className="svg-circle-progress"
                stroke={circleTwoStroke}
                cx={centre}
                cy={centre}
                r={radius}
                strokeWidth={strokeWidth}
                strokeDasharray={circumference}
                strokeDashoffset={offset}
                ref={circleRef}
                transform={`rotate(-90 ${centre} ${centre})`}
                shapeRendering="optimizeQuality"
            />
        </svg>
    );
}

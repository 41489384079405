import { createContext, useContext, useEffect, useState, FC } from "react";
import { useRos } from "./utils/rosProvider";
import { subscribe, buildTopicOptions, unsubscribe } from './utils/rosTopicCommands';
import { Message } from 'roslib';


interface ISubscriber {
    name : string;
    type : string;
    rate : number | undefined;
    queue_size? : number | undefined;
}

const MsgContext = createContext<Message>({});

/**
 * 
 * @param name - the name of the topic
 * @param type - the topic type
 * @param rate - the throttle rate for the subscriber
 * @param queue_size - the size of the queue
 * @returns 
 */
export const Subscriber : FC<ISubscriber> = ({children, name, type, rate}) => {
    const ROS = useRos();

    const [ message, setMessage ] = useState<Message>({});

    useEffect(() => {
        //console.log("Subscribing: ", name);

        //Message callback is pulled out here so it can be removed when unsubscribing.
        const messageCallback = (msg : Message) => {
            setMessage(msg);
        }

        const topic = subscribe(
            buildTopicOptions(ROS, name, type, rate), 
            messageCallback
        );

        return () => {
            if(topic){
                console.log("Unsubscribing");
                unsubscribe(topic, messageCallback);
            }
        }
    }, [ROS, name, type, rate]);

    return (
        <MsgContext.Provider value={message}>
            {children}
        </MsgContext.Provider>
    );
}

export const useMsg = () => {
    const context = useContext(MsgContext);
    if(context === undefined){
        throw new Error('useMsg must be used in a message provider');
    }
    return context;
}



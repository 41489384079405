import { useReducer, useRef } from "react";
import { BASE_URL } from '../../constants/constants';

type Params = {
    param : string;
    value : string | number;
}

interface State<T> {
    status : "init" | "fetching" | "error" | "fetched";  
    data?: T;
    error?: Error;
}

interface ReturnFetch<T> {
    state : State<T>;
    get : (params? : Array<Params>) => void;
    post : (body? : T) => void;
    abortRequest : (() => void | undefined) | undefined;
}

type Action<T> = 
    | { type: 'loading'}
    | { type: 'fetched'; payload: T}
    | { type: 'error'; payload: Error}



export function useFetch<T=unknown>(url: RequestInfo, options? : RequestInit): ReturnFetch<T> {

    const initialState: State<T> = {
        status : "init",
        error : undefined,
        data : undefined,
    }

    const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
        switch(action.type){
            case 'loading':
                return {...state, status : "fetching", data : undefined, error : undefined};
            case 'fetched':
                return {...state, status : "fetched", data : action.payload};
            case 'error':
                return {...state, status : "error", error : action.payload};
            default:
                return state;
        }
    }

    const [state, dispatch] = useReducer(fetchReducer, initialState);

    const abort = useRef<AbortController>();
    const cancelRequest = useRef<boolean>(false);

    const get = async (params? : Array<Params>) => {
        

        if(!options || options.method === 'GET' || !options.method){              //Default request is GET if method is not givin.
            if(params && params.length > 0){
                url = url + '?';
                for(let i = 0; i< params.length; i++){
                    url = url + params[i].param + '=' + params[i].value;
                    if(i !== params.length - 1){
                        url = url + '&'
                    }
                }
            }
        }
        
        request(url, options);
    }

    const post = (body? : T | undefined) => {
        let _options = {};

        if(body){
            _options = {
                ...options, 
                body : JSON.stringify(body),
                headers:  {
                    'Content-Type':'application/json' ,
                }
            }
        } else {
            _options = {
                ...options
            }
        }
        console.log(_options)
        console.log("Posting", _options);
        request(url, _options);
    }

    const request = async (url : RequestInfo, options? : RequestInit) => {
        dispatch({type : 'loading'});
        // console.log("Loading");
        // console.log(url,options)
        cancelRequest.current = false;

        const abortController = new AbortController();
        const signal = abortController.signal;
        abort.current = abortController;

        const newUrl = BASE_URL + url;
        console.log(newUrl)

        try {
            const res = await fetch(newUrl, {...options, signal});
            // console.log(res)
            if(!res.ok){
                throw new Error(res.statusText);
            }
            const json = (await res.json()) as T;

            if(cancelRequest.current) return;
            // console.log("Fetched!");
            dispatch({type: 'fetched', payload: json});
        } catch (e){
            if(cancelRequest.current) return;
            console.log(e);
            dispatch({type: 'error', payload: e as Error});
        } finally {
            abort.current = undefined;
        }
    }

    const abortRequest = () => {
        cancelRequest.current = true;
        if(abort.current){
            abort.current.abort();
        }
    }
    

    return { state, get, post, abortRequest};
}
    

import React, { createContext, useState, useContext } from 'react';

// Create a context to hold the observer status
const ObserverContext = createContext();

// Custom hook to use the observer context
export const useObserver = () => useContext(ObserverContext);

// Provider component that holds the state of IS_OBSERVER
export const ObserverProvider = ({ children }) => {
  const [isObserver, setIsObserver] = useState(false);

  return (
    <ObserverContext.Provider value={{ isObserver, setIsObserver }}>
      {children}
    </ObserverContext.Provider>
  );
};

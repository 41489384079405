import { FC, useEffect, useState } from "react";
import { useMsg } from "../../services/Ros/Subscriber";
import { ROBOT_URL, ROS_PORT } from "../../constants/constants";
import { useFetch } from "../../services/Requests/useFetch";
import { AutodockStopService } from "./AutodockStopService";

interface RobotStateMessage {
  data: number;
}

const parseRobotState = (message: RobotStateMessage) => {
  const stateMapping: Record<number, string> = {
    0: "IDLE",
    1: "UNDOCK",
    2: "READY",
    3: "DOCKING",
    4: "AUTO",
    5: "MANUAL",
    6: "NO GPS FIX",
    7: "UNDOCK",
  };

  const stateNumber = message.data;
  const stateString = stateMapping[stateNumber] || "UNKNOWN"; // Default to "UNKNOWN" if stateNumber is not in the mapping
  return stateString;
};

interface IRecordMission {
  t: string;
}

const options: RequestInit = {
  method: "GET",
};

export const DockingControl: FC = () => {
  const msg = useMsg();
  const parsedState = parseRobotState(msg as RobotStateMessage);
  const dock = useFetch<IRecordMission>('/api/missions/dock', options);
  const [dockingState, setDockingState] = useState(false);
  const [dockingCmd, setDockingCmd] = useState('');


  useEffect(() => {
    if (dockingState && dockingCmd) {
      dock.get([
        { param: 'mission', value: dockingCmd },
        { param: 'host', value: ROBOT_URL },
        { param: 'port', value: ROS_PORT },
        { param: 'dock', value: dockingCmd },
      ]);
      setDockingState(false);
      console.log(dockingCmd === 'dock' ? 'Docking' : 'Undocking');
    }
  }, [dockingState, dockingCmd, dock]);

  const handleDock = (cmd: string) => {
    setDockingCmd(cmd);
    setDockingState(true);
  };


  const getButtonLabel = () => {
    switch (parsedState) {
      case "UNDOCK":
        return "Undocking";
      case "DOCKING":
        return "Docking";
      case "AUTO":
      case "MANUAL":
        return "Driving";
      case "IDLE":
        return "Undock";
      case "READY":
        return "Dock";
      default:
        return "Disconnected";
    }
  };

  const isButtonDisabled = () => {
    return ["UNDOCK", "DOCKING", "AUTO", "MANUAL"].includes(parsedState);
  };

  return (
    <div>
      <button
        onClick={() => handleDock(parsedState === "READY" ? 'dock' : 'undock')}
        disabled={isButtonDisabled()}
      >
        {getButtonLabel()}
      </button>

      {/* Show the "Stop Docking" button when the state is "DOCKING" */}
      {parsedState === "DOCKING" && (
        <AutodockStopService/>
      )}
    </div>
  );
};

import { useState, useEffect } from 'react';
import { ToggleSwitchWidget } from "../../core/Widgets/ToggleSwitchWidget";
import { WEBSOCKET_URL, WEB_RTC_SERVER, AUTHREQ } from '../../constants/constants';
import { VideoComponentOverlay } from "../../core/Overlays/VideoComponentOverlay";
import { StatusBar } from "../StatusBar/StatusBar";
import { PTZWidget } from "../../core/Widgets/PTZWidget";
import { RealtimeUpdatesProvider } from '../../services/Requests/websocket';
import { RosSocket } from "../../services/Ros/RosSocket";
import { DrivingProvider } from "../../services/Ros/Driving/DrivingProvider";
import { DrivingControls } from "../DrivingControls/DrivingControls";
import { KeyboardShortcutProvider } from "../../services/General/KeyboardShortcutProvider";
import { MapWidget } from '../../core/Widgets/MapWidget';
import { PathPlanningOverlay } from '../PathPlanning/PathPlanningOverlay';
import { StreamSelector } from '../../core/Video/StreamSelector';
import { WarningOverlay } from "../../services/Notifications/WarningOverlay";
import { Subscriber } from "../../services/Ros/Subscriber";
import { ESTOP, FRONT_COLLISION, REAR_COLLISION, STATE, WARN_TOPIC } from "../../constants/topics";
import LightControlButtons from '../StatusBar/LightControlButtons'; // Import the new component
import { RecordingWidget } from '../../core/Widgets/RecordingWidget';
// import { RTSPViewer } from '../../services/Video/RTSPViewer';
import WEBRTCVideo from '../../services/Video/WEBRTCVideo';
import { ImageViewerOverlay } from '../ImageVIewer/ImageViewerOverlay';
import { DockingControl } from '../DrivingControls/DockingControl';
import { StopTravelOverlay } from '../ZoneTravel/StopTravelOverlay';
import EmergencyShutdown from '../Emergency/EmergencyShutdown';
import { Publisher } from '../../services/Ros/Publisher';
import { SettingsPageOverlay } from '../SettingsPage/SettingsPageOverlay';
import { EmergencyDrivingControls } from '../DrivingControls/EmergencyDrivingControls';
import { EmergencyModeTicker } from '../Emergency/EmergencyModeTicker';
import { Button } from 'react-bootstrap';
import { CollisionOverlay } from '../../services/Notifications/CollisionOverlay';
import { useAuth } from '../../utilities/http/routing/AuthContext';
// import { WEBRTCViewer}  from '../../services/Video/WEBRTCViewer';
import { useObserver } from '../../utilities/http/routing/ObserverContext';
import { useOperator } from '../../utilities/http/routing/OperatorContext';
import { ToastProvider } from '../../services/Notifications/ToastProvider';
import { BackendToastNotification } from '../BackendToastNotification';
import TeleopToggle from '../DrivingControls/TeleopToggle';
import { ServiceCaller } from '../../services/Ros/ServiceCaller';

console.log(`ros ${WEBSOCKET_URL}`)


const useLogout = () => {
  const { setIsAuthenticated } = useAuth();

  const logout = async () => {
    try {
      // Perform logout actions, e.g., call a logout API
      await fetch('/api/logout', { method: 'POST' });
      
      // Clear authentication tokens or session data
      localStorage.removeItem('authToken');
      
      // Update authentication state
      setIsAuthenticated(false);

      // Redirect to the logout URL or homepage
      window.location.href = '/logout';
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return logout;
};



const PageB = () => (
  <>
    {/* Add any components you need for PageB */}
  </>
);

export const HomePage = () => {
  const [isToggleOn, setIsToggleOn] = useState(false);
  const [isRecordOn, setIsRecordOn] = useState(false);
  const [isEmergencyOverride, setIsEmergencyOverride] = useState(false);
  const { isObserver } = useObserver();
  const { isOperator } = useOperator();
  const handleLogout = useLogout();


  const [stream, setStream] = useState<'front' | 'rear' | 'ptz'>('front');

  // console.log(isEmergencyOverride ? 'Collision Override' : 'Regular Driving')
  isEmergencyOverride ? console.log('Collision Override Active') : console.log('Collision Override Inactive')


  const handleToggleChange = () => {
    setIsToggleOn(prev => !prev);
    console.log("Toggled, isToggleOn:", !isToggleOn); // Log state after toggling
  };

  const handleRecordChange = () => {
    setIsRecordOn(prev => !prev);
    console.log("Toggled, isRecordOn:", !isRecordOn); // Log state after toggling
  };

  const updateStream = (stream: 'front' | 'rear' | 'ptz') => {
    setStream(stream);
    console.log('stream updated:', stream);
  };

  const activateEmergencyMode = () => {
    setIsEmergencyOverride(true);
    setIsToggleOn(true);
  };

  const deactivateEmergencyMode = () => {
    setIsToggleOn(false);
    setIsEmergencyOverride(false);
  }

  useEffect(() => {
    
    if (isObserver){
      console.log(`Operator is an Observer, unable to Activate Drive`)
      return
    }
    const pge = isToggleOn ? 'B' : 'A';

    console.log(pge); // Log state after it has been updated
  }, [isToggleOn]);


  return (
    <>
      {/* <RTSPViewer webRtcServer={WEB_RTC_SERVER} streamName={stream} /> */}
      <WEBRTCVideo server={WEB_RTC_SERVER} src={stream} />
      <KeyboardShortcutProvider>
        <VideoComponentOverlay>
          <RosSocket url={WEBSOCKET_URL} autoconnect={true} timeout={5000}>
          {!isObserver &&<ToggleSwitchWidget labelVal="Manual Mode" isChecked={isToggleOn} onChange={handleToggleChange} />}
          {!isObserver && <ServiceCaller name='/activate_teleop' type='std_srvs/srv/SetBool' >
            <TeleopToggle activateTeleop={isToggleOn} collisionOveride={isEmergencyOverride}/>
          </ServiceCaller>}
            {!isObserver &&<ToggleSwitchWidget labelVal="Recording" isChecked={isRecordOn} onChange={handleRecordChange} />}
            <br/>
            {isOperator &&
              <Button variant="danger" onClick={isEmergencyOverride ? deactivateEmergencyMode : activateEmergencyMode} >
                {isEmergencyOverride ? 'Deactivate' : 'Activate'}  Collision  Override
              </Button>
            }
            <Publisher name={ESTOP.name} type={ESTOP.type} rate={ESTOP.throttle}>
            {!isObserver &&<EmergencyShutdown/>}
            </Publisher>
            {(stream === 'ptz' &&!isObserver) && <PTZWidget />}
            {(stream === 'ptz' &&!isObserver) && <MapWidget /> }
            {(stream !== 'ptz' || isObserver) && <MapWidget position="widget-top-right" />}
            <StreamSelector updateStream={updateStream} />
            {!isObserver &&<LightControlButtons/>} {/* Pass the ros instance as a prop */}
            <Subscriber name={WARN_TOPIC.name} type={WARN_TOPIC.type} rate={WARN_TOPIC.throttle}>
              <WarningOverlay />
            </Subscriber>
            <StatusBar width="40%" />          
            <></>
            <DrivingProvider>
            {isToggleOn 
              ? (isEmergencyOverride 
                  ? <EmergencyDrivingControls /> 
                  : <DrivingControls />) 
              : <PageB />}
              <RealtimeUpdatesProvider>
                <PathPlanningOverlay isManualModeActive={isToggleOn}/>
              </RealtimeUpdatesProvider>
              <RealtimeUpdatesProvider>
                <StopTravelOverlay isManualModeActive={isToggleOn}/>
              </RealtimeUpdatesProvider>
            </DrivingProvider>
            <ImageViewerOverlay/>
            <SettingsPageOverlay/>
            <br/>
            <Subscriber name={STATE.name} type={STATE.type} rate={STATE.throttle}>
              {!isObserver &&<DockingControl />}
            </Subscriber>
            <Subscriber name={REAR_COLLISION.name} type={REAR_COLLISION.type} rate={REAR_COLLISION.throttle}>
              <CollisionOverlay direction='Rear' stream={stream}/>
            </Subscriber>
            <Subscriber name={FRONT_COLLISION.name} type={FRONT_COLLISION.type} rate={FRONT_COLLISION.throttle}>
              <CollisionOverlay direction='Front' stream={stream}/>
            </Subscriber>
            {isRecordOn === true && 
              <RealtimeUpdatesProvider>
                <RecordingWidget isManualModeActive={isToggleOn} />
              </RealtimeUpdatesProvider>
            }
          </RosSocket>
          {AUTHREQ && <Button variant="primary" onClick={handleLogout}>
            Logout
          </Button>}
          {isEmergencyOverride && <EmergencyModeTicker />}
          <ToastProvider>
            <BackendToastNotification/>
          </ToastProvider>
        </VideoComponentOverlay>
      </KeyboardShortcutProvider>
    </>
  );
};

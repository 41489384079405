import { FC } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faExpand } from '@fortawesome/free-solid-svg-icons';
//import { useToggle } from '../../services/General/useToggle';

interface WidgetCardProps {
    size? : {
        width? : string;
        height? : string;
    };
    // pos : {
    //     bottom? : string | undefined;
    //     top? : string | undefined;
    //     left? : string | undefined;
    //     right? : string | undefined;
    // };
    position? : 'widget-top-left' | 'widget-top-right' | 'widget-bottom-left' | 'widget-bottom-right' | string;
}

type HeaderComponent = FC;
type BodyComponent = FC;
type FooterComponent = FC;

type MainComponent = FC<WidgetCardProps> 
    & { 
        Header : HeaderComponent, 
        Body : BodyComponent, 
        Footer : FooterComponent
};


// interface StyleProps {
//     right? : string;
//     bottom? : string;
//     top? : string;
//     left? : string;
//     width : string;
//     height : string;
//     transition? : string;
//     transform? : string; 
// }

const WidgetCard: MainComponent = ({ children, position }) => {

    // let unexpandedStyle: StyleProps = { ...size, ...pos };

    // const [ expanded, toggleExpanded ] = useToggle(false);

    // useEffect(() => {
    //     if(expanded === true){
    //         position = 'widget-full-screen';
    //     } 
    // }, [expanded]);

    return (
        <>
            <Card className={`${position} mdc-elevation--z24 widget-card`} >
                {children}
            </Card>
            {/* <UtilModal id="main-modal" show={expanded} onClose={handleExpand}>
                {expanded ? children : undefined}
            </UtilModal>  */}
        </>
    );
}


const Header : FC = ({children}) => {
    return (
        <Card.Header className='justify-content-between d-flex'>
            <FontAwesomeIcon icon={faExpand} className="widget-interaction-button"/>
            {children}
            <FontAwesomeIcon icon={faMinus} className="widget-interaction-button" />
        </Card.Header>
    );
}

const Body : FC = ({children}) => {
    return (
        <Card.Body className='p-0'>
            {children}
        </Card.Body>
    );
}

const Footer : FC = ({children}) => {
    return (
        <Card.Footer>
            {children}
        </Card.Footer>
    );
}

WidgetCard.Header = Header;
WidgetCard.Body = Body;
WidgetCard.Footer = Footer;

export default WidgetCard;


import { useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
// import useKeyPress from '../../services/Controls/useKeyPress';
import { useToggle } from '../../services/General/useToggle';
import { PathPlanningBody } from './PathPlanningBody';
//import { PathPlanningList } from './PathPlanningList';
import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { useConnection } from '../../services/Ros/Connection';
import { ConnectionMessage } from '../../core/Messages/ConnectionMessage';
import { useDrivingMethod, DrivingTypes } from '../../services/Ros/Driving/DrivingProvider';
//import { MissionPlanOverview } from './MissionPlanOverview';
import '../../css/StreamSelector.css';  // Import the CSS file
import '../../css/path-planning.css'; 

interface PathPlanningOverlayProps {
    isManualModeActive: boolean;
}

export const PathPlanningOverlay = ({ isManualModeActive }: PathPlanningOverlayProps) => {

    const [show, setShow] = useToggle(false);
    const { control, setDisabled } = useDrivingMethod();
    const drivingMethod = useRef<DrivingTypes>(control);
    const connection = useConnection();


    useEffect(() => {
        if(show === true){
            console.log("Disabling Driving While mission planing is up.");
            drivingMethod.current = control;
            setDisabled(true);
        } else {
            console.log("Setting method back to what it was. ", drivingMethod.current);
            setDisabled(false);
        }
    },[show]);

    // useKeyPress('p', setShow);


    const render = () => {
        //const {state : {data, status}} = fetchMissionPlans;

        if(connection === false){
            return <ConnectionMessage icon={faPlug} text='Robot Not Connected' colour='dark'/>
        } else { 
            return <PathPlanningBody />;
        }
    }


    return (
        <>
            <Button
                variant='info'
                size="lg"
                onClick={setShow}
                className={show ? "button.selected" : "button"}
                disabled={isManualModeActive}
            >
                <FontAwesomeIcon icon={faMapMarkerAlt} inverse={true} size="lg" />
            </Button>
            <Modal 
                fullscreen='lg-down' 
                className='path-planning-modal' 
                dialogClassName='modal-90w overflow-hidden' 
                size='xl' 
                centered 
                show={show} 
                onHide={setShow} >
                <Modal.Header closeButton>
                    <Modal.Title className='text-dark'>Mission Plan</Modal.Title>
                </Modal.Header>
                <Modal.Body className='ps-0 pb-0 pt-0'>
                    {render()}
                </Modal.Body>
            </Modal>
        </>
    );
}
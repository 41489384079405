import { memo } from 'react';
import '../../css/WarningOverlay.css';
import { useMsg } from "../../services/Ros/Subscriber";

interface WarningMessage {
    data: number;
}

const parseMessage = (message: WarningMessage) => {
    return message.data;
}

const WarningOverlayMemo = () => {
    const msg = useMsg();
    const parsedWarningMessage = parseMessage(msg as WarningMessage);
    let showWarning = false;
    let warnMessage = "";
    let backgroundColor = "";

    if (parsedWarningMessage >= 90) {
        showWarning = true;
        warnMessage = "Warning - Entering Red Zone - Imminent Shutdown";
        backgroundColor = "rgba(255, 0, 0, 0.7)"; // Red
    } else if (parsedWarningMessage >= 50) {
        showWarning = true;
        warnMessage = "Warning - Approaching Red Zone Turn Back";
        backgroundColor = "rgba(255, 69, 0, 0.7)"; // Dark Orange
    } else if (parsedWarningMessage >= 10) {
        showWarning = true;
        warnMessage = "Warning - Approaching Yellow Zone";
        backgroundColor = "rgba(255, 255, 0, 0.7)"; // Yellow
    }

    return (
        <>
            {showWarning && (
                <div className="warning-overlay" style={{ backgroundColor }}>
                    <p>{warnMessage}</p>
                </div>
            )}
        </>
    );
};

export const WarningOverlay = memo(WarningOverlayMemo);

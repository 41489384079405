import { FC, ReactElement, memo, ReactNode } from 'react';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { GOOGLE_MAPS_API_KEY } from '../../constants/constants';
import { GoogleMap } from './GoogleMap';

interface IGoogleMapContainer extends google.maps.MapOptions {
    id: string;
    children?: ReactNode;
    onClick?: (e: google.maps.MapMouseEvent) => void; // Add onClick prop
}

const MapContainerMemo: FC<IGoogleMapContainer> = ({ id, children, zoom, center, onClick }) => {
    const render = (status: Status): ReactElement => {
       
        switch (status) {
            case Status.LOADING:
                console.log(status)
                return <div>Loading...</div>;
            case Status.FAILURE:
                console.log(status)
                return <div>Failed to load</div>;
            default:
                return (
                    <GoogleMap
                        id={id}
                        center={center}
                        zoom={zoom}
                        onClick={onClick} // Pass onClick prop
                        mapTypeId='satellite'
                        disableDefaultUI={true}
                    >
                        {children}
                    </GoogleMap>
                );
        }
    };

    return (
        <Wrapper apiKey={GOOGLE_MAPS_API_KEY} render={render} libraries={['places']}>
            {/* Render GoogleMap component based on status */}
        </Wrapper>
    );
};

export const MapContainer = memo(MapContainerMemo);

import { useEffect, useState, FC, MouseEventHandler, useRef } from "react";

interface MarkerProps extends google.maps.MarkerOptions {
    onClick?: MouseEventHandler;
}

export const Marker: FC<MarkerProps> = (props) => {
    const { onClick, ...markerOptions } = props;
    const [marker, setMarker] = useState<google.maps.Marker>();
    const listener = useRef<google.maps.MapsEventListener | undefined>();

    useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.Marker());
        }

        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    useEffect(() => {
        if (marker) {
            marker.setOptions(markerOptions);
            if (onClick) {
                listener.current = google.maps.event.addListener(marker, 'click', onClick);
            }
        }

        return () => {
            if (marker && onClick && listener.current) {
                google.maps.event.removeListener(listener.current);
            }
        };
    }, [marker, markerOptions, onClick]);

    return null;
};

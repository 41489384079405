import {
    useEffect,
    useRef,
    createContext,
    ReactChild,
    useState,
    useContext
} from 'react';

import { REALTIME_UPDATES_URL } from '../../constants/constants';

// Create a new WebSocket connection
const websocket = new WebSocket(REALTIME_UPDATES_URL);

// Define the shape of the context
interface SocketContextType {
    ws: WebSocket;
    lastMessage: MessageEvent | null;
}

// Create context with WebSocket and lastMessage
export const SocketContext = createContext<SocketContextType>({
    ws: websocket,
    lastMessage: null,
});

interface ISocketProvider {
    children: ReactChild;
}

/**
 * WebSocket Provider that ensures the connection stays alive and
 * shares the last message and the WebSocket instance through context.
 * 
 * @param props - children components that will have access to WebSocket context
 * @returns WebSocket context provider
 */
export const RealtimeUpdatesProvider = (props: ISocketProvider) => {
    const [ws, setWs] = useState<WebSocket>(websocket);
    const [lastMessage, setLastMessage] = useState<MessageEvent | null>(null);
    const timeout = useRef<NodeJS.Timeout>(setTimeout(() => '', 10)); // Timer ref for reconnection handling

    /**
     * Make a WebSocket connection and keep it open if it closes.
     */
    useEffect(() => {
        const onClose = () => {
            // Attempt to reconnect after a delay
            timeout.current = setTimeout(() => {
                setWs(new WebSocket(REALTIME_UPDATES_URL));
            }, 3000);
        };

        const onMessage = (event: MessageEvent) => {
            console.log('Message received from WebSocket:', event.data);
            setLastMessage(event); // Store the last message received
        };

        const onOpen = () => {
            console.log('Opened WebSocket:');
        };

        const onError = () => {
            console.log('Error on WebSocket:');
        };

        // Add WebSocket event listeners
        ws.addEventListener('close', onClose);
        ws.addEventListener('message', onMessage);
        ws.addEventListener('open', onOpen);
        ws.addEventListener('error', onError);

        // Cleanup event listeners and timeout on unmount
        return () => {
            if (timeout.current) {
                window.clearTimeout(timeout.current);
            }
            ws.removeEventListener('close', onClose);
            ws.removeEventListener('message', onMessage);
        };
    }, [ws, setWs]);

    return (
        <SocketContext.Provider value={{ ws, lastMessage }}>
            {props.children}
        </SocketContext.Provider>
    );
};

/**
 * Hook to use WebSocket context in the app.
 * @returns WebSocket context for consumption in other components.
 */
export const useRealtimeUpdates = () => {
    const context = useContext(SocketContext);

    if (!context) {
        throw new Error('useRealtimeUpdates must be used within a RealtimeUpdatesProvider');
    }

    return context;
};

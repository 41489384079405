import { FC } from "react";
import { RobotVital } from "../Vitals/RobotVital";
import { useMsg } from "../../../services/Ros/Subscriber";
import { MIN_BATTERY, BATTERY_RANGE, CURRENT_RANGE } from "../../../constants/constants";

interface BatteryMessage {
    percentage : number;
    voltage: number;
    current: number;
}

interface IBatteryIndicator { 
    name : string,
    unit : string
}

const parseBatteryVoltage = (message: BatteryMessage) => {
    return message.voltage;
}

const parseBatteryMessage = (message: BatteryMessage) => {
    const bat_volt = message.voltage - MIN_BATTERY;
    const bat_percent = (bat_volt / BATTERY_RANGE) > 1 ? 1: (bat_volt / BATTERY_RANGE);
    return Math.floor(bat_percent * 100);
}

const parseCurrentVoltage = (message: BatteryMessage) => {
    return message.current;
}

const parseCurrentMessage = (message: BatteryMessage) => {
    const bat_volt = message.current;
    const bat_percent = (bat_volt / CURRENT_RANGE) > 1 ? 1: (bat_volt / CURRENT_RANGE);
    return Math.floor(bat_percent * 100);
}

export const BatteryIndicator : FC<IBatteryIndicator> = ({ name, unit }) => {
    const msg = useMsg();
    if (name === 'Battery'){
        const parsed = parseBatteryMessage(msg as BatteryMessage);
        const parsed_voltage = parseBatteryVoltage(msg as BatteryMessage);
        return (
            <>
                <RobotVital name={name} unit={unit} progress={parsed} value={parsed_voltage}/> 
            </>
        );
    }else{
        const parsed = parseCurrentMessage(msg as BatteryMessage);
        const parsed_voltage = parseCurrentVoltage(msg as BatteryMessage);
        return (
            <>
                <RobotVital name={name} unit={unit} progress={parsed} value={parsed_voltage}/> 
            </>
        );
    }

    
}


import { RosProvider } from "./utils/rosProvider";
import { Connection } from "./Connection";
import { FC } from "react";

interface IRosSocket {
    url : string;
    autoconnect : boolean;
    timeout : number;
}


export const RosSocket : FC<IRosSocket> = ({children, url, autoconnect, timeout}) => {
    // if(children === undefined || children === null){
    //     return <></>
    // }
    return (
        <RosProvider>
            <Connection url={url} autoconnect={autoconnect} timeout={timeout}>
                {children}
            </Connection>
            {/* <RosConnection url={url} autoconnect={autoconnect} timeout={timeout}/> */}
            
        </RosProvider>
    )
}
import { 
    useEffect, 
    useState, 
    createContext, 
    FC, 
    useContext 
} from "react";

import { useMobileCheck } from "../../General/useMobileCheck";

export enum DrivingTypes {
    DISABLED = -1,
    AUTOMATED = 0,
    GAMEPAD = 1,
    KEYBOARD = 2,
    NIPPLE = 3
}

interface IDrivingProvider {
    control : DrivingTypes;
    disabled : boolean;
    setDisabled : (setting : boolean) => void;
    setMethod : (setting : DrivingTypes) => void;
}

const DrivingContext = createContext<IDrivingProvider>({
    control : DrivingTypes.DISABLED, 
    disabled : false, 
    setDisabled : () => {}, 
    setMethod : () => {}
});

export const DrivingProvider : FC = ({children}) => {

    const { GAMEPAD, KEYBOARD, NIPPLE } = DrivingTypes;

    const isMobile = useMobileCheck();

    const [ method, setMethod ] = useState<DrivingTypes>(KEYBOARD);
    const [ disabled, setDisabled ] = useState<boolean>(false);

    useEffect(() => {
        //Check if the device is a tablet or mobile.
        if(isMobile){
            setMethod(NIPPLE);
            return;
        }
    }, [isMobile]);
 

    useEffect(() => {
        /**
         * Handles a gamepad connection event
         */
        const handleGamepadConnected = (): void => {
            console.log("Gamepad connected");
            setMethod(GAMEPAD);
        }
                
        /**
         * Handles a gamepad disconnect
         */
        const handleGamepadDisconnected = (): void => {
            //console.log("Reverting to Default Control");
            if(isMobile){
                setMethod(NIPPLE);
            } else {
                setMethod(KEYBOARD);
            }
        }   

        window.addEventListener('gamepadconnected', handleGamepadConnected);
        window.addEventListener('gamepaddisconnected', handleGamepadDisconnected);

        return () => {
            window.removeEventListener('gamepadconnected', handleGamepadConnected);
            window.removeEventListener('gamepaddisconnected', handleGamepadDisconnected);
        }
    },[]);


    return(
        <DrivingContext.Provider 
            value={{ 
                control : method, 
                disabled : disabled, 
                setDisabled : setDisabled, 
                setMethod : setMethod
            }}>
            {children}
        </DrivingContext.Provider>
    );
}


export const useDrivingMethod = () => {
    const context = useContext(DrivingContext);
    // console.log(DrivingContext)
    if(context === undefined){
       throw new Error("This must be used inside a DrivingContext element"); 
    }

    return context;
}
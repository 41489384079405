import { FC, memo } from 'react';
import { useConnection } from '../../../services/Ros/Connection';
import { Stack } from 'react-bootstrap';

//import '../../../css/robot-status.css';

interface RobotStateProps{
    name : string;
    value?: string | null;
}

/**
 * This must be wrapped in a subscriber tag at some point to use useMsg().
 * @param param0 
 * @returns 
 */
const RobotStateMemo: FC<RobotStateProps> = ({ name,value=null }) => {

    const connected = useConnection();

    const value_text = ( !value || connected === false) ? '-' : value;


    return(
        <Stack direction='horizontal' gap={2}>
            <span className="vital-text my-auto vital-type">{name} :</span>
            {value === null ? <span className="vital-text my-auto vital-progress-text">{``}</span> : <span className="vital-text my-auto vital-progress-text">{`${value_text}`}</span> }
        </Stack>
    );
}

//Export a memoized 
export const RobotState = memo(RobotStateMemo);
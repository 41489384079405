import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    useEffect, 
    FC, 
    SyntheticEvent, 
    useState, 
    forwardRef,
    MouseEventHandler,
    Children,
    CSSProperties,
    isValidElement
} from 'react';

import { Dropdown, Form } from 'react-bootstrap';
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem';
import { useFetch } from '../../services/Requests/useFetch';
import { LIGHT_PRIMARY } from '../../constants/constants';

type Target = {target : { value : string }};

type SelectCallback = (
    eventKey : string | null,
    e : SyntheticEvent<unknown>
) => void;

interface Props {
    onSelect : (id : string) => void;
}


interface ForwardedRefProps {
    onClick : MouseEventHandler;
    className? : string;
}

interface MenuProps {
    style? : CSSProperties;
    className? : string;
    onSelect : (e : Target) => void;
}

const options : RequestInit = {
    method : "GET"
}


export const AssetSelect : FC<Props> = ({ onSelect }) => {

    //Fetch all the mission plan data.
    const missions = useFetch<string[]>('/api/missions/get_assets', options);
    const [ toggled, setToggled ] = useState<boolean>(false);
    const [ selected, setSelected ] = useState<string | undefined>();

    //When the modal loads get the latest mission plans.

    useEffect(() => {
        missions.get();

        return () => {
            missions.abortRequest && missions.abortRequest();
        }
    }, []);

    useEffect(() => {
        const { state : { status, data } } = missions;

        if(status === 'fetched'){
            if(data && data.length > 0){
                console.log(data);
            }
        }
    }, [missions.state.data]);

    useEffect(() => {
        if(toggled === true){
            missions.get();     //Init the fetch request.
        }

        return () => {
            if(missions.abortRequest){
                missions.abortRequest();        //Abort any current requests on cleanup.
            }
        }
    }, [toggled]);

    const handleToggled = () => {
        setToggled(!toggled);
    }   

    const handledSelected : SelectCallback = (eventKey, e) => {
        // console.log(eventKey,e);
        // console.log((e.target as HTMLElement).innerText);
        if(!eventKey) return;
        // console.log(eventKey&&eventKey);

        if(eventKey && eventKey !== "-1"){
            setSelected((e.target as HTMLElement).innerText);
            onSelect((e.target as HTMLElement).innerText);
        }
    }


    //Custom dropdown toggle
    const CustomToggle = forwardRef<HTMLDivElement, ForwardedRefProps>(({ children, onClick}, ref) => (
        <div
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            className="custom-select-dropdown py-2 d-flex align-items-center text-dark mx-3"
        >
            {children}
            <FontAwesomeIcon icon={faChevronDown} className="ms-auto me-2" color={LIGHT_PRIMARY}/>
        </div>
    ));

    const CustomMenu = forwardRef<HTMLDivElement, MenuProps>(({ children, style, className }, ref) => {

        const [value, setValue ] = useState<string>('');

        return ( 
            <div
                ref={ref}
                style={style}
                className={className}
            >
                <Form.Control
                    autoFocus
                    className='mx-3 my-2'
                    style={{width : "calc(100% - 31px)"}}
                    placeholder='Search...'
                    onChange={(e : {target : { value : string }}) => setValue(e.target.value)}
                />
                <ul className="list-unstyled">
                    {
                        Children.toArray(children).filter((child) => {
                            if(isValidElement<DropdownItemProps>(child)){
                                return !value || child.props.children?.toString().toLowerCase().includes(value);
                            }
                        })
                    }
                </ul>
            </div>
        );
    });


    const render = () => {
        if(missions.state.status === 'fetched'){
            // console.log(missions.state.data)
            return missions.state.data?.map((mission, index) => {
                // console.log(mission,index,"peep")
                return (
                    <Dropdown.Item key={index + 1} eventKey={mission} className="d-flex">
                        {mission}   
                    </Dropdown.Item>
                );
            });
        }
    }

    return (
        <Dropdown onToggle={handleToggled} onSelect={handledSelected} className="mb-4 mt-3">
            <Dropdown.Toggle as={CustomToggle}>
                <span className={`${selected ?? "text-secondary"}  ms-2`}>
                    {selected ?? "Select an Asset"}
                </span>
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu} style={{width : "calc(100% - 31px)"}} className="mission-planning-menu">
                {render()}
                <Dropdown.Item key={257} eventKey={'snapshot'} className="d-flex">
                    {'snapshot'}   
                </Dropdown.Item>
                <Dropdown.Item key={258} eventKey={'FrontObstruction'} className="d-flex">
                    {'FrontObstruction'}   
                </Dropdown.Item>
                <Dropdown.Item key={259} eventKey={'RearObstruction'} className="d-flex">
                    {'RearObstruction'}   
                </Dropdown.Item>
            </Dropdown.Menu>

        </Dropdown>
    );
}


export interface DiagnosticsMessage {
    cpu? : number | null | undefined;
    disk? : number | null | undefined;
    battery? : number | null | undefined;
    lat? : number;
    lng? : number;
}

interface IDiagnosticsMessage {
    _message : DiagnosticsMessage | null | undefined;
}

export type AccurateTimer = () => void; 

export const validateSubscription = (msg: IDiagnosticsMessage, key : keyof DiagnosticsMessage) => {
    if(msg._message !== undefined && msg._message !== null && msg._message[key] !== null && msg._message[key] !== undefined){
        return msg._message[key];
    } else {
        return null;
    }
}

/**
 * **************************************** Timer based functions *******************************************
 */


/**
 * The timer on javascripts setInterval was not accurate enough. This interval timers accounts for timer slipping.
 * @param callback 
 * @param interval 
 * @returns 
 */
export const accurateIntervalTimer = (callback : () => void, interval : number) : (() => void) => {
    let counter = 1;
    let timeoutId : NodeJS.Timeout;
    const startTime = Date.now();

    const main = () => {
        const nowTime = Date.now();
        const nextTime = startTime + counter * interval;
        timeoutId = setTimeout(main, interval - (nowTime - nextTime));

        counter += 1;
        callback();
    }

    timeoutId = setTimeout(main, interval);

    return () => {
        clearTimeout(timeoutId);
    }
}

export const debounce = (callback : () => void, timeout : number = 200) => {
    let timer : NodeJS.Timeout;
    return (...args : any) => {
        clearTimeout(timer);
        timer = setTimeout(() => {callback.apply(this, args); }, timeout)
    }
}

/**
 * Maps a value within a range to a new range. Based off the formula:
 * 
 *               y = (x - a)/(b - a) * (d - c) +  c;
 * 
 * @param a - original range lower
 * @param b - original range upper
 * @param c - new range lower
 * @param d - new range upper
 * @param x - number in the original range
 *  
 * @returns The new value mapped to the new range.
 */
export const mapToNewRange = (a : number, b : number, c : number, d : number, x : number) => {
    return (x - a) * ((d - c) / (b - a)) + c;
}


export const capitalizeFirstLetter = (str : string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const removeFromLargeArray = <T>(arr : Array<T>, item : T) => {
    const index = arr.indexOf(item);
    return index > -1 ? arr.splice(index, 1) : arr;
}

export const removeObjectFromArray = <T, K extends keyof T>(arr : Array<T>, item : T[K], key : K) => {
    const outputArr = [];

    for(let i = 0; i < arr.length; i++){
        if(arr[i][key] !== item){
            outputArr.push(arr[i]);
        }
    }

    return outputArr;
}

export const removeFromArray = <T>(arr : Array<T>, item : T) => {
    const outputArr = [];

    for(let i = 0; i < arr.length; i++){
        if(arr[i] !== item){
            outputArr.push(arr[i]);
        }
    }

    return outputArr;
}

export const includesKeyValue = <T, K extends keyof T>(arr : Array<T>, item : T[K], key : K) => {

    if(arr.some(e => e[key] === item)){
        return true;
    } else {
        return false;
    }
}


export const findObjectByKey = (obj : any, key : string) : Object | null => {
    var result = null;
    if(obj instanceof Array){
        for(let i = 0; i < obj.length; i++){
            result = findObjectByKey(obj[i], key);
            if(result){
                break;
            }
        }
    } else {
        for(var prop in obj) {
            if(prop === key) {
                return obj;
            }
            if(obj[prop] instanceof Object || obj instanceof Array) {
                result = findObjectByKey(obj[prop], key);
                if(result){
                    break;
                }
            }
        }
    }

    return result;
}

export const orderArrayOfObjects = (arr : Array<{ [key : string ] : any}>, keys : string) => {
    const prop = keys.split('.');
    const length = prop.length;

    return arr.sort((a : {[key : string ] : any}, b : {[key : string ] : any}) => {
        var i = 0;
        while( i < length ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
        if(a < b){
            return -1;
        } else if (a > b){
            return 1;
        } else {
            return 0;
        }
    });
}

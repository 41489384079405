import { useState, useEffect } from 'react';
import { convertTimestampToNumber } from '../../utilities/datetime';
import { useFetch } from '../../services/Requests/useFetch';

interface Props {
    running : boolean;
    startTime? : string | null | undefined;
    missionId: number;
}

const postOptions: RequestInit = {
    method: "POST",
  };

export const Stopwatch = ({running,missionId, startTime} : Props) => {


    const [ time, setTime ] = useState<number>(0);
    const updateDuration = useFetch('/api/missions/update_mission', postOptions);




    useEffect(() => {

        let interval : NodeJS.Timeout | undefined = undefined;
        
        if(running){

            const timestamp = startTime ? convertTimestampToNumber(startTime) : 0;
            let diff = 0;
            if(timestamp === 0){
                diff = 0;
            } else {
                diff = new Date().getTime() - timestamp;
            }

            setTime(diff);

            interval = setInterval(() => {
                setTime((prevTime) => prevTime + 1000);
            }, 1000);
        } else if (!running) {
            if (time !== 0){
                console.log(`Completed in ${time}`)
                const missionUpdate = { mission_id: missionId, duration: time};
                updateDuration.post(missionUpdate);
            }
            setTime(0);
            if(interval){
                clearInterval(interval);
            }
        }

        return () => clearInterval(interval);

    }, [running]);

    return (
        <div className="stopwatch">
            <div className="numbers">
                <span>{time === 0 ? "- -" : ("0" + Math.floor((time / 60000) % 60)).slice(-2)}:</span>
                <span>{time === 0 ? "- -" : ("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
                {/* <span>{time === 0 ? "- -" : ("0" + ((time / 10) % 100)).slice(-2)}</span> */}
            </div>
        </div>
    )
}
import { createContext, useCallback, useContext, useState, FC, useEffect } from "react";
import { Toast, ToastContainer } from "react-bootstrap";


type AddToastType = (toast : JSX.Element) => void;

const ToastContext = createContext<AddToastType>(() => '');

export const ToastProvider : FC = ({ children }) => {

    const [ toasts, setToasts ] = useState<Array<string>>([]);

    useEffect(() => {
        let timer : NodeJS.Timeout;
        console.log(`toasts: ${toasts}`)
        if(toasts.length > 0){
            timer = setTimeout(
                () => setToasts(toasts => toasts.slice(1)),
                5000
            );
        }

        return () => {
            if(timer){
                clearTimeout(timer);
            }
        }
    }, [toasts]);

    const addToast = useCallback((toast) => {
        console.log(toast)
        setToasts(toasts => [...toasts, toast]);
    },[setToasts]);

     // Dismiss a toast manually by removing it from the list
     const dismissToast = (index: number) => {
        setToasts(toasts => toasts.filter((_, i) => i !== index));
    };

    const renderToasts = toasts.map((item,index) => {
        return (
            <Toast className={'toast-text'}
            bg="light" key={index}
            onClose={() => dismissToast(index)}
            >
                <Toast.Header closeButton>
                    <strong className="me-auto">Notification</strong>
                </Toast.Header>
                <Toast.Body>
                    {item}
                </Toast.Body>
            </Toast>
        );
    });

    return (
        <ToastContext.Provider value={addToast}>
            {children}
            <ToastContainer position='bottom-end' style={{padding:'20px'}}>
                {renderToasts}
            </ToastContainer>
        </ToastContext.Provider>
    );
}

export const useNotification = () => {
    const context = useContext(ToastContext);
    if(context === undefined){
       throw new Error("This must be used inside a Connection element"); 
    }

    return context;
}
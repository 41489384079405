import { createContext, useContext, useEffect, useState, FC } from "react";
import { useRos } from "./utils/rosProvider";
import { buildTopicOptions, publish } from './utils/rosTopicCommands';
import { Message, Topic } from 'roslib';

interface IPublisher {
    name: string;
    type: string;
    rate?: number;
}

const MsgContext = createContext<(msg: Message) => void>(() => {});

/**
 * 
 * @param name - the name of the topic
 * @param type - the topic type
 * @param rate - the throttle rate for the publisher
 * @returns 
 */
export const Publisher: FC<IPublisher> = ({ children, name, type, rate }) => {
    const ROS = useRos();
    const [topic, setTopic] = useState<Topic | null>(null);

    useEffect(() => {
        const topicOptions = buildTopicOptions(ROS, name, type, rate);
        const rosTopic = new Topic(topicOptions);

        // Advertise the topic when the component mounts
        rosTopic.advertise();

        setTopic(rosTopic);

        // Cleanup by unadvertising the topic when the component unmounts
        return () => {
            console.log('clean up pub')
            rosTopic.unadvertise();
        };
    }, [ROS, name, type, rate]);

    const publishMessage = (msg: Message) => {
        if (topic) {
            publish(topic, msg);
        }
    };

    return (
        <MsgContext.Provider value={publishMessage}>
            {children}
        </MsgContext.Provider>
    );
};

export const usePublisher = () => {
    const context = useContext(MsgContext);
    if (context === undefined) {
        throw new Error('usePublisher must be used within a Publisher provider');
    }
    return context;
};

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    useEffect, 
    FC, 
    SyntheticEvent, 
    useState, 
    forwardRef,
    MouseEventHandler,
    Children,
    CSSProperties,
    isValidElement
} from 'react';

import { Dropdown, Form } from 'react-bootstrap';
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem';
import { useFetch } from '../../services/Requests/useFetch';
import { LIGHT_PRIMARY } from '../../constants/constants';

type Target = {target : { value : string }};

type SelectCallback = (
    eventKey : string | null,
    e : SyntheticEvent<unknown>
) => void;

interface Props {
    onSelect : (id : string) => void;
    selectedValue? : string;
}


interface ForwardedRefProps {
    onClick : MouseEventHandler;
    className? : string;
}

interface MenuProps {
    style? : CSSProperties;
    className? : string;
    onSelect : (e : Target) => void;
}

const options : RequestInit = {
    method : "GET"
}


export const StopSelect : FC<Props> = ({ onSelect, selectedValue }) => {

    //Fetch all the mission plan data.
    const missions = useFetch<string[]>('/api/missions/get_stops', options);
    const [ toggled, setToggled ] = useState<boolean>(false);
    const [ selected, setSelected ] = useState<string | undefined>();

    //When the modal loads get the latest mission plans.

    useEffect(() => {
        missions.get();

        return () => {
            missions.abortRequest && missions.abortRequest();
        }
    }, []);

    useEffect(() =>{    
        console.log(selectedValue)
        if (selectedValue !== ''){
            setSelected(selectedValue);
        }
    }, [selectedValue] );

    useEffect(() => {
        const { state : { status, data } } = missions;

        if(status === 'fetched'){
            if(data && data.length > 0){
                console.log(data);
            }
        }
    }, [missions.state.data]);

    useEffect(() => {
        if(toggled === true){
            missions.get();     //Init the fetch request.
        }

        return () => {
            if(missions.abortRequest){
                missions.abortRequest();        //Abort any current requests on cleanup.
            }
        }
    }, [toggled]);

    const handleToggled = () => {
        setToggled(!toggled);
    }   

    const handledSelected : SelectCallback = (eventKey, e) => {
        console.log(eventKey,e);
        console.log((e.target as HTMLElement).innerText);
        if(!eventKey) return;
        console.log(eventKey&&eventKey);

        if(eventKey && eventKey !== "-1"){
            // console.log('Here we are')
            setSelected((e.target as HTMLElement).innerText);
            onSelect((e.target as HTMLElement).innerText);
        }
    }


    //Custom dropdown toggle
    const CustomToggle = forwardRef<HTMLDivElement, ForwardedRefProps>(({ children, onClick}, ref) => (
        <div
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            className="custom-select-dropdown py-2 d-flex align-items-center text-dark mx-3"
        >
            {children}
            <FontAwesomeIcon icon={faChevronDown} className="ms-auto me-2" color={LIGHT_PRIMARY}/>
        </div>
    ));

    const CustomMenu = forwardRef<HTMLDivElement, MenuProps>(({ children, style, className }, ref) => {

        const [value, setValue ] = useState<string>('');

        return ( 
            <div
                ref={ref}
                style={style}
                className={className}
            >
                <Form.Control
                    autoFocus
                    className='mx-3 my-2'
                    style={{width : "calc(100% - 31px)"}}
                    placeholder='Select...'
                    onChange={(e : {target : { value : string }}) => setValue(e.target.value)}
                />
                <ul className="list-unstyled">
                    {
                        Children.toArray(children).filter((child) => {
                            if(isValidElement<DropdownItemProps>(child)){
                                return !value || child.props.children?.toString().toLowerCase().includes(value);
                            }
                        })
                    }
                </ul>
            </div>
        );
    });


    const render = () => {
        if (missions.state.status === 'fetched') {
            const stopsByZone: Record<string, string[]> = {};
    
            // Group stops by their respective zones
            missions.state.data?.forEach((mission) => {
                const [zone, stop] = mission.split('/');
                if (stop.startsWith('stop')) {
                    if (!stopsByZone[zone]) {
                        stopsByZone[zone] = [];
                    }
                    stopsByZone[zone].push(stop);
                }
            });
    
            // Find the stop with the highest number in each zone
            const stopsToExclude = new Set<string>();
    
            Object.entries(stopsByZone).forEach(([zone, stops]) => {
                const highestStop = stops.reduce((maxStop, currentStop) => {
                    const currentNumber = parseInt(currentStop.replace('stop', ''), 10);
                    const maxNumber = parseInt(maxStop.replace('stop', ''), 10);
                    return currentNumber > maxNumber ? currentStop : maxStop;
                }, stops[0]);
    
                stopsToExclude.add(`${zone}/${highestStop}`);
            });
    
            // Render the dropdown items, excluding the highest stop in each zone
            return missions.state.data?.map((mission, index) => {
                const [zone, stop] = mission.split('/');
                if (
                    !stopsToExclude.has(mission) &&
                    stop !== 'stop0' &&
                    stop !== 'stop1' &&
                    zone !== 'zone0'
                ) {
                    return (
                        <Dropdown.Item key={index + 1} eventKey={mission} className="d-flex">
                            {mission}
                        </Dropdown.Item>
                    );
                }
                return null; // Skip rendering for excluded stops
            });
        }
        return null; // Return null if the status is not 'fetched'
    };
    

    return (
        <Dropdown onToggle={handleToggled} onSelect={handledSelected} className="mb-4 mt-3">
            <Dropdown.Toggle as={CustomToggle}>
                <span className={`${selected ?? "text-secondary"}`}>
                    {selected ?? "Select a Stop"}
                </span>
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu} style={{width : "calc(100% - 31px)"}} className="mission-planning-menu">
                {render()}
            </Dropdown.Menu>

        </Dropdown>
    );
}

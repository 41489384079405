 //import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SUCCESS, ERROR } from '../../../constants/constants';
import { useConnection } from '../../../services/Ros/Connection';
import { faTruckMonster } from '@fortawesome/free-solid-svg-icons';
//import { faPlugCircleBolt } from '@fortawesome/free-solid-svg-icons';
import { faLinkSlash } from '@fortawesome/free-solid-svg-icons';

//  <label htmlFor="robot-status" className="text-center">
//  <FontAwesomeIcon icon={icon} color={color} size="lg" style={{ verticalAlign : "middle" }}/>
// </label>
 
 export const RobotConnectionIndicator = () => {
 
     //console.log("Status Container Ready State:  ", readyState);
     const readyState = useConnection();
 
    const icon = readyState ? faTruckMonster : faLinkSlash;
    const color = readyState ? SUCCESS : ERROR;
    const animation = readyState ? "fadeOnline" : "fadeOffline"; 
 
     return(
        <div className='robot-status-slider'>
            <div className={`${animation} status-label-container-new d-flex align-items-center justify-content-center`} style={{background : color}}>
                <FontAwesomeIcon icon={icon} color="#e1e1e1" size='2x' className='med-icon'/>
            </div>
        </div>
     );
 }
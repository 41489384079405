import { FC, useEffect, useRef } from 'react';
import nipplejs from 'nipplejs';
import { JoystickManager, JoystickManagerOptions, JoystickOutputData, EventData } from 'nipplejs';
import { Topic } from 'roslib';
import { drive, setDeadzone, terminate } from '../../../services/Ros/Driving/drivingCommands';
import { useConnection } from '../../../services/Ros/Connection';

type Positions = "left" | "right";

interface IDriveNipple {
    id : string;
    topic : Topic;
    position : Positions;
    size : number;
    containerWidth : number;
    containerHeight : number;
}

const PUBLISH_RATE = 100;

export const DriveNipple : FC<IDriveNipple> = ({ id, topic, position, size, containerWidth, containerHeight }) => {

    const connected = useConnection();

    const joystickManager = useRef<JoystickManager>();
    
    const sustainCheckTimeout = useRef<NodeJS.Timeout>(setTimeout(() => '', 10));

    const clearSustainCheckTimeout = () => {
        if(sustainCheckTimeout.current){
            clearTimeout(sustainCheckTimeout.current);
        }
    }


    const driveNippleJoy = (id: string,data : JoystickOutputData) => {

        const speedMultiplier = 0.5;
        const angleMultiplier = 1;

        const deadzone = 0.15;                          //Set a deadzone just like with the gamepad.
        const forceVectorX = data.vector.x * -1;        //Only X seems to be backwards so multiply by -1.
        const forceVectorY = data.vector.y;

        let [x, y] = setDeadzone(forceVectorX, forceVectorY, deadzone);
        
        y = y * speedMultiplier;
        x = x * angleMultiplier;
        
        let stamped = false;
        if (topic.name === '/cmd_vel_dashboard' || topic.name === '/cmd_vel_override'){
            stamped = true;
        }
        if (id === 'nippleright'){
            drive(topic, {x:0, y:0, z:0}, {o:0, a:0, t:x},stamped);                        //Send a angular drive command
        }
        
        if (id === 'nippleleft'){
            drive(topic, {x:y, y:0, z:0}, {o:0, a:0, t:0},stamped);                        //Send a angular drive command
        }
        

        /**
         * If the user is not constantly moving the joystick the robot will stop. 
         * This times out if there hasn't been a move command 20ms after the pubish time.
         * Then runs an interval timer to sustain the previous movement values until the next move event or joystick end.
         */

        sustainCheckTimeout.current = setTimeout(() => {
            //console.log("End hasn't occured, continuing drive.");
            driveNippleJoy(id,data);
        }, PUBLISH_RATE + 20);
    }


    const handleJoyMoveEvent = (_evt : EventData, data : JoystickOutputData) => {
        //console.log(data);
        clearSustainCheckTimeout();
        driveNippleJoy(id,data);
    }

    const handleJoyEndEvent = (_evt : EventData, _data : JoystickOutputData) => {
        clearSustainCheckTimeout();
        let stamped = false;
        if (topic.name === '/cmd_vel_dashboard' || topic.name === '/cmd_vel_override'){
            stamped = true;
        }
        terminate(topic,stamped);
    }

    useEffect(() => {

        if(connected === false){
            return;
        }

        const nippleArea = document.getElementById(id);

        if(!nippleArea || nippleArea === null){
            return;
        }

        const joyOptions : JoystickManagerOptions = {
            zone : nippleArea,
            mode : "static",
            position : {left : '50%', top : '50%'},
            restJoystick : true,
            restOpacity : 0.5,
            shape : "circle",
            follow : false,
            size : size
        }

        joystickManager.current = nipplejs.create(joyOptions);

        //event listeners

        joystickManager.current.on('move', handleJoyMoveEvent);
        joystickManager.current.on('end', handleJoyEndEvent);

        return () => {
            if(joystickManager.current){
                joystickManager.current.off('move', handleJoyMoveEvent);
                joystickManager.current.off('end', handleJoyEndEvent);
                joystickManager.current.destroy();
            }
        }

    }, [connected]);


    return (
        <div 
            className={`joystick-container ${position}`} 
            style={{
                height : containerHeight, 
                width : containerWidth
            }} 
            id={id}>

        </div>
    );
}
import { UserManager, WebStorageStateStore } from 'oidc-client';

const oidcConfig = {
  authority: 'https://tp-portal.mafdigitallab.co.nz/application/o/mercury-1/', // Base URL of your Authentik server
  client_id: 'dEC6ElC5aF0Kn2Umdp9TeOizk8gzz6z3u5juUgW1', // Client ID from Authentik
  redirect_uri: 'https://mercury.wrybillrobotics.com/auth/callback', // Your React app's callback URL
  response_type: 'code',
  scope: 'openid profile email',
  post_logout_redirect_uri: 'https://mercury.wrybillrobotics.com/Not-Found', // Where to redirect after logout
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};


const userManager = new UserManager(oidcConfig);


export default userManager;

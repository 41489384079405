import React, { useEffect } from 'react';
import userManager from './userManager'; // Import your OIDC userManager instance

const Logout = () => {
  useEffect(() => {
    // Sign out the user and redirect to the specified post-logout URL
    userManager.signoutRedirect().catch(err => {
      console.error('Error during logout:', err);
    });
  }, []);

  return <div>Logging out...</div>;
};

export default Logout;

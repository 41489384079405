import { useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons';
import { useToggle } from '../../services/General/useToggle';
import { ImageViewerBody } from './ImageViewerBody';
import { useDrivingMethod, DrivingTypes } from '../../services/Ros/Driving/DrivingProvider';
import '../../css/StreamSelector.css';  // Import the CSS file
import '../../css/path-planning.css'; 

export const ImageViewerOverlay = () => {
    const [show, setShow] = useToggle(false);
    const { control, setDisabled } = useDrivingMethod();
    const drivingMethod = useRef<DrivingTypes>(control);

    useEffect(() => {
        if (show === true) {
            console.log("Disabling Driving While image viewer is up.");
            drivingMethod.current = control;
            setDisabled(true);
        } else {
            console.log("Setting method back to what it was. ", drivingMethod.current);
            setDisabled(false);
        }
    }, [show]);

    const render = () => {
        return <ImageViewerBody />;
    }

    return (
        <>
            <Button
                variant='info'
                size="lg"
                onClick={setShow}
                // className={show ? "selected" : ""}
            >
                <FontAwesomeIcon 
                    icon={faCameraRetro} 
                    size="lg" 
                    inverse={true}
                    // color="#007bff"
                />
            </Button>
            <Modal
                fullscreen='lg-down'
                className='path-planning-modal'
                dialogClassName='modal-90w overflow-hidden'
                size='xl'
                centered
                show={show}
                onHide={setShow}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-dark'>Asset Viewer</Modal.Title>
                </Modal.Header>
                <Modal.Body className='ps-0 pb-0 pt-0'>
                    {render()}
                </Modal.Body>
            </Modal>
        </>
    );
}

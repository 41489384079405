import { FC, useEffect } from "react";
import { useMsg } from "../../services/Ros/Subscriber";

interface RobotStateMessage {
    data: number;
}

interface IRobotStateIndicator { 
    name: string;
    onStateChange: (state: string) => void; // Callback to handle state change
}

const parseRobotState = (message: RobotStateMessage): string => {
    const stateMapping: Record<number, string> = {
        0: "IDLE",
        1: "UNDOCK",
        2: "READY",
        3: "DOCKING",
        4: "AUTO",
        5: "MANUAL",
        6: "NO GPS FIX",
        7: "UNDOCK"
    };

    const stateNumber = message.data;
    return stateMapping[stateNumber] || "UNKNOWN"; // Default to "UNKNOWN" if stateNumber is not in the mapping
}

export const RobotState: FC<IRobotStateIndicator> = ({ onStateChange }) => {
    const msg = useMsg();
    const parsedState = parseRobotState(msg as RobotStateMessage);

    useEffect(() => {
        onStateChange(parsedState);
    }, [parsedState, onStateChange]);

    return null; // No need to return any JSX since we're only handling state change
}

import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { usePublisher } from "../../services/Ros/Publisher";
import '../../css/EmergencyShutdown.css'; // Import a CSS file for styling
import { Message } from 'roslib';

const EmergencyShutdown: React.FC = () => {
  const [isArmed, setIsArmed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const publish = usePublisher();

  const handleArm = () => {
    setIsArmed(true);
    console.log('Emergency shutdown armed.');
  };

  const handleShutdown = () => {
    if (isArmed) {
      console.log('Emergency shutdown initiated!');
      // Publish true to the emergency shutdown topic
      const message: Message = { data: true };
      publish(message);
      setIsArmed(false); // Reset the armed state after shutdown
      setIsModalOpen(false); // Close the modal after shutdown
    } else {
      console.log('Please arm the shutdown button first.');
    }
  };

  const openModal = () => {
    handleArm();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsArmed(false);
    setIsModalOpen(false);
  };

  return (
    <div className="emergency-shutdown-container">
      <Button 
        variant='warning' 
        onClick={openModal} 
        disabled={isArmed}
      >
        Arm Emergency Shutdown
      </Button>

      <Modal show={isModalOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Emergency Shutdown</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to initiate the emergency shutdown?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleShutdown}>
            Yes, Shutdown
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EmergencyShutdown;

import { useEffect, useState, FC } from "react";
import { Topic, Message } from "roslib";

import { COMPASS_TOPIC } from "../../constants/topics";
import { useRos } from "../../services/Ros/utils/rosProvider";
import { useMsg } from "../../services/Ros/Subscriber";
import { subscribe, buildTopicOptions, unsubscribe } from "../../services/Ros/utils/rosTopicCommands";
import useDeepCompareEffect from "../../services/General/useDeepCompareEffect";

interface IGPSMessage {
  altitude?: number;
  header?: {
    frame_id: string;
    seq: number;
    stamp: {
      secs: number;
      nsecs: number;
    };
  };
  latitude: number;
  longitude: number;
  position_covariance?: Array<number>;
  position_covariance_type?: number;
  status?: {
    status: number;
    service: number;
  };
}

interface RobotTrackingMarkerProps extends google.maps.MarkerOptions {}

export const RobotTrackingMarker: FC<RobotTrackingMarkerProps> = (options) => {
  const ROS = useRos();
  const [marker, setMarker] = useState<google.maps.Marker>();

  const gpsFix = useMsg() as IGPSMessage;

  useEffect(() => {
    let topic: Topic | undefined;

    const callback = (msg: Message) => {
      const dir = msg as { data: number };
      const offset_dir = dir.data;

      marker?.setIcon({
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 6,
        fillOpacity: 1,
        strokeWeight: 2,
        fillColor: '#5384ED',
        strokeColor: '#FFFFFF',
        rotation: offset_dir,
      });
    };

    if (!marker) {
      const newMarker = new google.maps.Marker();
      setMarker(newMarker);
    } else {
      topic = subscribe(
        buildTopicOptions(ROS, COMPASS_TOPIC.name, COMPASS_TOPIC.type, COMPASS_TOPIC.throttle),
        callback
      );
    }

    return () => {
      if (topic) {
        unsubscribe(topic);
      }
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker, ROS]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  useDeepCompareEffect(() => {
    if (marker && gpsFix.latitude != null && gpsFix.longitude != null) {
      const gpsMsg = new google.maps.LatLng(gpsFix.latitude, gpsFix.longitude);
      const map = marker.getMap();
      
      if (map) {
        map.setOptions({
          center: gpsMsg,
          
        });
      }
      marker.setZIndex(999)
      marker.setPosition(gpsMsg);
    }
  }, [gpsFix, marker]);

  return null;
};

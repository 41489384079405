import { FC } from "react";
import { useMsg } from "../../../services/Ros/Subscriber";
import { RobotState } from "../Vitals/RobotState";

interface RobotStateMessage {
    data: number;
}

interface IRobotStateIndicator { 
    name: string;
}

const parseRobotState = (message: RobotStateMessage) => {
    const stateMapping: Record<number, string> = {
        0: "IDLE",
        1: "UNDOCK",
        2: "READY",
        3: "DOCKING",
        4: "AUTO",
        5: "MANUAL",
        6: "NO GPS FIX",
        7: "UNDOCK"
    };

    const stateNumber = message.data;
    const stateString = stateMapping[stateNumber] || "UNKNOWN"; // Default to "UNKNOWN" if stateNumber is not in the mapping
    return stateString;
}

export const RobotIndicator: FC<IRobotStateIndicator> = ({ name }) => {
    const msg = useMsg();
    const parsedState = parseRobotState(msg as RobotStateMessage);
    return (
        <>
            <RobotState name={name} value={parsedState}/> 
        </>
    );
}

import { FC } from 'react';

interface NotFoundProps {

}

export const NotFound: FC<NotFoundProps> = () => {
    const imgStyle = {
        // backgroundImage: `url(${NotFoundOverlay})`
    };

    return (
        <div className="background-image-login" style={imgStyle}>
            <div className="login-signup">
                <div className="display-1">
                    {/* 404 */}
                </div> 
                <div className="lead">
                    {/* Page Not Found */}
                </div>
            </div>
        </div>
    );
}
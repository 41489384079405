import { 
    useEffect, 
    FC, 
    useState,
    CSSProperties
} from 'react';

import { LIGHT_PRIMARY } from '../../constants/constants';

interface Props {
    selectedValue?: string;
}

export const StopDisplay: FC<Props> = ({ selectedValue }) => {
    const [selected, setSelected] = useState<string | undefined>();

    // When the modal loads, get the latest mission plans.
    useEffect(() => {
        console.log(selectedValue);
        if (selectedValue !== '') {
            setSelected(selectedValue);
        }
    }, [selectedValue]);

    return (
        <div className="selected-stop-box py-2 px-3 mb-4 mt-3" style={boxStyle}>
            <span className={`${selected ? 'text-dark' : 'text-secondary'}`}>
                {selected ?? "No Stop Nearby"}
            </span>
        </div>
    );
}

// Use CSSProperties to define the type for the style object
const boxStyle: CSSProperties = {
    border: `1px solid ${LIGHT_PRIMARY}`,
    borderRadius: '4px',
    backgroundColor: '#f8f9fa',
    display: 'inline-block',
    width: '100%',
    textAlign: 'center',
};

import { Message, Topic } from 'roslib';


interface ITopic {
    ros: ROSLIB.Ros; 
    name: string; 
    messageType: string; 
    compression?: string | undefined; 
    throttle_rate?: number | undefined; 
    queue_size?: number | undefined; 
    latch?: boolean | undefined; 
    queue_length?: number | undefined;
};

export const buildTopicOptions = (
    ros : ROSLIB.Ros, 
    name : string,
    type : string,
    rate? : number | undefined,
    queue? : number | undefined,
    ) : ITopic => {
        return {
            ros : ros,
            name : name,
            messageType : type,
            throttle_rate : rate,
            queue_size : queue
        }
    }

export const getTopics = (
    ros : ROSLIB.Ros, 
    callback : (topics: { topics: string[]; types: string[]; }) => void,
    failedCallback : ((error: any) => void)
    ) => {
        ros.getTopics(callback, failedCallback);
}

export const subscribe = (settings : ITopic, callback : (msg : Message) => void) => {
    const topic = new Topic(settings);
    topic.subscribe(callback);
    return topic;
}

export const unsubscribe = (topic : Topic, callback? : (msg : Message) => void) => {
    if(callback){
        topic.unsubscribe(callback);
    } else {
        topic.unsubscribe();
    }
}

export const publish = (topic : Topic, message : Message) => {
    topic.publish(message);
    console.log(topic,message)
}

export const filterTopics = (topics: { topics: string[]; types: string[]; }, searchTerms : Array<string>) : Array<string> => {
    let _topics = topics.topics ?? [];
    let types = topics.types ?? [];

    let foundTopics = [];
    let typesLength = types.length;
    let searchTermsLength = searchTerms.length;

    for(let i = 0; i < typesLength; i++){
        for(let j = 0; j < searchTermsLength; j++){
            if(types[i] === searchTerms[j]){
                foundTopics.push(_topics[i]);
            }
        }
    }
    return foundTopics;
}


import { Topic } from 'roslib';
import { useRef, useEffect, FC } from 'react';
import { useConnection } from '../../../services/Ros/Connection';
import { driveWithLoop, terminate } from '../../../services/Ros/Driving/drivingCommands';
import { useShortcutsStatus } from '../../../services/General/KeyboardShortcutProvider';

interface KeyboardAction {
    pressed : boolean;
    // lockedKeys : string[];
    // begin : () => void;
}

interface KeyboardControllerInterface {
    [name : string] : KeyboardAction 
}

interface IDriveKeyboard {
    topic : Topic;
}

// const M_PER_S = 0.3;
// const R_PER_S = 0.6;
const M_PER_S = 0.4;
const R_PER_S = 0.8;

export const DriveKeyboard : FC<IDriveKeyboard> = ({topic}) => {

    const connected = useConnection();      //Check the connecttion status of Ros.
    const { enabled }= useShortcutsStatus();
    
    const interval = 100;

    //const robotController = useRef<RobotController>(new RobotController(connected, topic));
    const clearTimer = useRef<(() => void) | null | undefined>(null);

    const keyboardController:KeyboardControllerInterface = {
        'w' : { pressed : false },
        'a' : { pressed : false },
        's' : { pressed : false },
        'd' : { pressed : false },
        'e' : { pressed : false },
    };

    const drive = (): void => {
        //Software emergency stop

        if(keyboardController['e'].pressed === true){
            let stamped = false;
            if (topic.name === '/cmd_vel_dashboard' || topic.name === '/cmd_vel_override'){
                stamped = true;
            }
            terminate(topic,stamped);
            Object.keys(keyboardController).forEach(key => {
                keyboardController[key].pressed = false;
            });
        } else if(keyboardController['w'].pressed && keyboardController['s'].pressed){
            return;
        } else if (keyboardController['a'].pressed && keyboardController['d'].pressed){
            return;
        } else if(keyboardController['w'].pressed && keyboardController['a'].pressed){
            //robotController.current.forwardLeft();
            driveLoop(M_PER_S, 0, 0, 0, 0, R_PER_S);
            return;
        } else if (keyboardController['w'].pressed && keyboardController['d'].pressed){
            //robotController.current.forwardRight();
            driveLoop(M_PER_S, 0, 0, 0, 0, -R_PER_S);
            return;
        } else if (keyboardController['s'].pressed && keyboardController['a'].pressed){
            //robotController.current.backLeft();
            driveLoop(-M_PER_S, 0, 0, 0, 0, R_PER_S);
            return;
        } else if (keyboardController['s'].pressed && keyboardController['d'].pressed){
            //robotController.current.backRight();
            driveLoop(-M_PER_S, 0, 0, 0, 0, -R_PER_S);
            return;
        } else if (keyboardController['w'].pressed){
            //robotController.current.forward();
            driveLoop(M_PER_S,0,0,0,0,0);
            return;
        } else if (keyboardController['a'].pressed){
            //robotController.current.left();
            driveLoop(0,0,0,0,0,R_PER_S);
            return;
        } else if (keyboardController['s'].pressed){
            //robotController.current.backward();
            driveLoop(-M_PER_S, 0, 0, 0, 0, 0);
            return;
        } else if (keyboardController['d'].pressed){
            //robotController.current.right();
            driveLoop(0, 0, 0, 0, 0, -R_PER_S);
            return;
        } else {
            return;
        }
    }

    const driveLoop = (x : number, y : number, z : number, o : number, a : number, t : number) => {
        clearOldTimer();
        let stamped = false;
        if (topic.name === '/cmd_vel_dashboard' || topic.name === '/cmd_vel_override'){
            stamped = true;
        }
        clearTimer.current = driveWithLoop(topic,{x,y,z},{o,a,t}, interval,stamped);
    }

    const clearOldTimer = () => {
        if(clearTimer.current){
            clearTimer.current();   //Clear any timer loop currently running.
        }
    }

    /**
    * This function handles a key down event by setting the pressed value for the key object to true.
    * @param e Keyboard event
    */
    const handleKeyDown = (e: KeyboardEvent): void => {
        if(e.repeat){return}                                    //Make sure when the key is held down it doesn't fire hundreds of keydown events.
        if(keyboardController[e.key]){
            keyboardController[e.key].pressed = true;
            drive();
        }
    }

    /**
     * This function handles a key up event by setting the pressed value for the key object to false, diasabling any functions running while the key is pressed.
     * @param e Key up event
     */
    const handleKeyUp = (e: KeyboardEvent): void =>{
        let count = 0;
        if(keyboardController[e.key]){
            keyboardController[e.key].pressed = false;
            clearOldTimer();
            Object.keys(keyboardController).forEach(key => {
                keyboardController[key].pressed && count++;
            });
            if(count === 0){
                let stamped = false;
                if (topic.name === '/cmd_vel_dashboard' || topic.name === '/cmd_vel_override'){
                    stamped = true;
                }
                terminate(topic,stamped);
            } else {
                drive();
            }
        }
    }


    useEffect(() => {
        if(connected === false){
            return;
        }

        if(enabled === false){
            return;
        }
        
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        
        return () => {
            clearOldTimer();
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        }
    }, [connected, enabled]);

    return <></>;
}
import React, { useState } from "react";
import styles from "../../css/ToggleSwitchWidget.module.css";

interface ToggleSwitchProps {
  defaultChecked?: boolean;
  labelVal: string;
  isChecked: boolean; // Define isChecked prop
  onChange: () => void;
}

export const ToggleSwitchWidget: React.FC<ToggleSwitchProps> = ({
  // defaultChecked = false,
  labelVal,
  isChecked,
  onChange,
}) => {
  const [label, setLabel] = useState(labelVal);

  const handleToggle = () => {
    onChange(); // Call the parent component's onChange function to handle toggle state
  };

  const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(event.target.value);
  };

  return (
    <div className={styles.toggleSwitchContainer}>
      <input
        type="text"
        value={label}
        onChange={handleLabelChange}
        placeholder="Manual Mode"
        className={styles.labelInput}
        disabled= {true}
      />
      <label className={styles.switch}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleToggle}
        />
        <span className={styles.slider}></span>
      </label>
    </div>
  );
};

export default ToggleSwitchWidget;

import { useEffect, useState, createContext, FC, useContext, useRef } from "react";
import { Ros } from "roslib";
import { useRos } from "./utils/rosProvider";

interface IRosConnection {
    url: string;
    autoconnect: boolean;
    timeout: number;
}

const ConnectionContext = createContext<boolean>(false);

/**
 * Connection Provider for active ROS connection. Allows access to the useConnection hook within the provider. 
 * Used for determining if we have a connection to the robot or not.
 */
export const Connection: FC<IRosConnection> = ({ children, url, autoconnect, timeout }) => {
    const ROS: Ros = useRos();  // The active ROS instance
    const autoConnectTimer = useRef<NodeJS.Timeout>(); // Timer ref for auto-reconnection if a connection cannot be established.
    const isExiting = useRef<boolean>(false);          // Determines if we set the state on page close.
    const [connected, setConnected] = useState<boolean>(false); // Connection state.

    useEffect(() => {
        const connect = (ros: Ros, url: string) => {
            ros.connect(url);
        };

        const close = (ros: Ros) => {
            console.log("ROS Connection closing");
            ros.close();
        };

        const setup = (ros: Ros, url: string) => {
            ros.on("connection", () => {
                console.log("Connection established");
                setConnected(true);
            });

            ros.on("error", (error: any) => {
                console.error("Error During Ros Connection", error);
            });

            ros.on("close", () => {
                console.log('closed');
                if (!isExiting.current) {
                    setConnected(false);
                }
                if (autoconnect && !isExiting.current) {
                    autoConnectTimer.current = setTimeout(() => {
                        console.log("Retrying ROS Connection...");
                        connect(ROS, url);
                    }, timeout); // Attempt a reconnect after the client closes.
                }
            });
        };

        setup(ROS, url);
        connect(ROS, url);

        // Add event listener for page unload to gracefully close the WebSocket
        const handleBeforeUnload = () => {
            isExiting.current = true;
            clearTimeout(autoConnectTimer.current);
            close(ROS);  // Close ROS connection gracefully
        };
        
        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            // Clean up on component unmount or page unload
            isExiting.current = true;
            clearTimeout(autoConnectTimer.current);
            console.log("Closing ROS");
            close(ROS);
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [ROS, url, autoconnect, timeout]);

    return (
        <ConnectionContext.Provider value={connected}>
            {children}
        </ConnectionContext.Provider>
    );
};

export const useConnection = () => {
    const context = useContext(ConnectionContext);
    if (context === undefined) {
        throw new Error("This must be used inside a Connection element");
    }
    return context;
};

import { MouseEventHandler } from 'react';
import { Button, Col, ProgressBar, Row } from 'react-bootstrap';
import { StopSelect } from './StopSelect';
import { StopData } from './StopTravelBody';
import { StopDisplay } from './StopDisplay';

interface Props {
    currentStop? : StopData | null | undefined;
    onSelect : (id : string) => void;       //Callback to handle the selection of a mission plan.
    onRun : MouseEventHandler;              //Callback to run to run the mission plan.
    onRunStop : MouseEventHandler
    selectedValue? : string;
    running : boolean;
    safe: boolean;
    home:boolean;
    progress?: number;
}

//@ts-ignore
export const StopTravelControlBanner = ({currentStop, onSelect, onRun, onRunStop, selectedValue, running,safe, home,progress } : Props) => {
    // console.log(selectedValue)
    return (
        <div className='mission-planning-banner p-3 mb-4 bg-white rounded' >
            <Row>
            <span className='text-dark h5'>Stop Select</span>
                <Col sm={5} >
                    <span className='text-dark h6 justify-content-center align-items-center'>Current Stop</span>
                    <StopDisplay selectedValue={`${currentStop?.zone??'...'}/${currentStop?.stop??'...'}`}/>
                </Col>
                <Col sm={1} className="text-center d-flex justify-content-center align-items-center">
                    <span className="text-dark h">&#8594;</span> {/* This renders the → symbol */}
                </Col>
                <Col sm={5}>
                    <span className='text-dark h6 justify-content-center align-items-center'>Selected Stop</span>
                    <StopSelect onSelect={onSelect} selectedValue={selectedValue}/>
                </Col>
            </Row>
            { running && <div className='mission-info mb-3 ps-3 pe-3'>
                <ProgressBar animated now={progress??0} variant="success" id="mission-progress" className='mb-3'/>
            </div>}
            <div className='d-flex'>
                <Button variant="primary" onClick={onRun} disabled={running || home} className="flex-fill me-2">
                    Go Home
                </Button>
                <Button variant="primary" onClick={onRunStop} disabled={running || selectedValue === '' || !safe} className="flex-fill me-2">
                    Travel Between Stops
                </Button>
            </div>
        </div>
    );
}
import {PTZ_SERVER } from '../../constants/constants';
const PTZService = {
    movePTZ: async (direction, speed = 1.0,timeout = 1) => {
        try {
            const response = await fetch(PTZ_SERVER+'/ptz', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    direction,
                    speed,
                    timeout
                })
            });

            if (!response.ok) {
                throw new Error('Failed to move PTZ');
            }

            return response.json();
        } catch (error) {
            console.error('Failed to move PTZ', error);
            throw error; // Re-throw the error for handling in the UI or other parts of your application
        }
    },
    gotoHomePTZ: async () => {
        try {
            const response = await fetch(PTZ_SERVER+'/ptz_service', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                })
            });

            if (!response.ok) {
                throw new Error('Failed to gotoHome PTZ');
            }

            return response.json();
        } catch (error) {
            console.error('Failed to move PTZ', error);
            throw error; // Re-throw the error for handling in the UI or other parts of your application
        }
    }
    ,
    snapshotPTZ: async () => {
        try {
            const response = await fetch(PTZ_SERVER+'/ptz_snapshot', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                })
            });

            if (!response.ok) {
                throw new Error('Failed to gotoHome PTZ');
            }

            return response.json();
        } catch (error) {
            console.error('Failed to move PTZ', error);
            throw error; // Re-throw the error for handling in the UI or other parts of your application
        }
    },
    gotoPreset: async (preset, speed = 1.0,timeout = 1) => {
        try {
            const response = await fetch(PTZ_SERVER+'/go_to_preset', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    preset,
                    speed,
                    timeout
                })
            });

            if (!response.ok) {
                throw new Error('Failed to go to preset PTZ');
            }

            return response.json();
        } catch (error) {
            console.error('Failed to go to PTZ', error);
            throw error; // Re-throw the error for handling in the UI or other parts of your application
        }
    },
    setPreset: async (preset,timeout = 1) => {
        try {
            const response = await fetch(PTZ_SERVER+'/set_preset', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    preset,
                    timeout
                })
            });

            if (!response.ok) {
                throw new Error('Failed to set preset PTZ');
            }

            return response.json();
        } catch (error) {
            console.error('Failed to set preset PTZ', error);
            throw error; // Re-throw the error for handling in the UI or other parts of your application
        }
    }
};

export default PTZService;

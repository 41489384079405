import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';


interface IConnectionMessage {
    icon : IconDefinition;
    text : string;
    colour? : 'dark' | 'light';
}

/**
 * 
 * @param icon - Icon to include in the connection message
 * @param text - The text to display
 * @param colour - The colour of the text (dark or light)
 * @returns A message stating why an error occured.
 */
export const ConnectionMessage : FC<IConnectionMessage> = ({icon, text, colour}) => {
    return (
        <div style={{whiteSpace : "pre-line"}} className={`text-center centre-video-text video-connection-message ${colour === 'dark' ? 'text-dark' : undefined}`}>

            <FontAwesomeIcon icon={icon} size="2x"/>
            <div className="display-4 video-playback-error mt-3">
                    {text}
            </div>      
        </div>
    );
}
import { useContext, useState, createContext, FC } from "react";

interface KeyboardShortcutType {
    enabled : boolean;
    setEnabled : (enabled : boolean) => void;
}

const KeyboardShortcutContext = createContext<KeyboardShortcutType>({
    enabled : true,
    setEnabled : () => ''
});


/**
 * Context provider to check if keyboard shortcuts used by useKeyPress should be enabled or not.
 * This is mainly used when typing is needed in the app and we don't want typing to either drive the robot or change the UI based on keyboard shortcuts.
 * @returns A provider for the app to enable and disable key presses.
 */
export const KeyboardShortcutProvider : FC = ({children}) => {

    const [ enabled, setEnabled ] = useState<boolean>(true);
    
    
    return (
        <KeyboardShortcutContext.Provider value={{enabled, setEnabled}}>
            {children}
        </KeyboardShortcutContext.Provider>
    );
}

export const useShortcutsStatus = () => {
    const context = useContext(KeyboardShortcutContext);
    if(context === undefined){
       throw new Error("This must be used inside a KeyboardShortcutProvider element"); 
    }

    return context;
}
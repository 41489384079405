import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import '../../css/mission-plan.css';
import { useFetch } from '../../services/Requests/useFetch';

interface AssetSelection {
  [zone: string]: { 
    [stop: string]: { 
      assets: { [key: string]: string } 
    } 
  };
}

interface Props {
  missionName: string;
  refresh: (activeIndex: number) => void
}

const options: RequestInit = {
  method: "GET"
}
const postOptions: RequestInit = {
  method: "POST",
  headers: {
    'Content-Type': 'application/json'
  }
}

// Define the interface for each stop's assets
interface Assets {
  [assetName: string]: string; // assetName is a key with a string value
}

// Define the interface for each zone's stops
interface Stops {
  [stopName: string]: Assets; // stopName is a key with an Assets object
}

// Define the main interface for the whole data structure
interface MissionData {
  [zoneName: string]: Stops; // zoneName is a key with a Stops object
}

const AssetSelectionForm: React.FC<Props> = ({ missionName,refresh }) => {
  const asset_data = useFetch<MissionData>('/api/missions/get_data', options);
  const mission_plan = useFetch('/api/missions/missionplan_create', postOptions);
  const [selectedAssets, setSelectedAssets] = useState<AssetSelection>({});
  const [expandedZone, setExpandedZone] = useState<string | null>(null);
  const [expandedStop, setExpandedStop] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState<Boolean>(false);

  useEffect(() => {
    asset_data.get();

    return () => {
      asset_data.abortRequest && asset_data.abortRequest();
    }
  }, []);

  useEffect(() => {
    const { state: { status, data } } = asset_data;
    

    if (status === 'fetched') {
      if (data) {
        if (data['zone0']){
          delete data['zone0']
        }
        
      }
    }
  }, [asset_data.state.data]);

  const handleAssetToggle = (zone: string, stop: string, asset: string, value: string) => {
    // if (zone === 'zone0') return; // Prevent changes to stop0
    setSelectedAssets((prev) => {
      const zoneData = { ...prev[zone] };
      const stopAssets = { ...zoneData[stop]?.assets };
      if (stopAssets[asset]) {
        delete stopAssets[asset];
      } else {
        stopAssets[asset] = value;
      }
      zoneData[stop] = { assets: stopAssets };
      return { ...prev, [zone]: zoneData };
    });
  };

  const handleAccordionToggleZone = (zone: string) => {
    setExpandedZone(expandedZone === zone ? null : zone);
    setExpandedStop(null); // Collapse all stops when changing zones
  };

  const handleAccordionToggleStop = (stop: string) => {
    setExpandedStop(expandedStop === stop ? null : stop);
  };


  const handleConfirm = async () => {
    const mission_id = missionName; // Replace with your mission_id
    const mission_data = selectedAssets;
    console.log(missionName, selectedAssets);
    mission_plan.post({ mission_id, mission_data });
    refresh(-2)
    setSubmitted(true);
  };

  return (
    <div className="modal-content waypoint-form-body">
      <div className="accordion-container">
        {asset_data.state.data && Object.entries(asset_data.state.data).map(([zone, stops]) => (
          <div key={zone} className="accordion" >
            {(zone !== 'zone0') && <div className="accordion-summary" onClick={() => handleAccordionToggleZone(zone)}>
              <h3>{zone}</h3>
              <FontAwesomeIcon
                icon={expandedZone === zone ? faChevronUp : faChevronDown}
                style={{ marginLeft: 'auto' }}
              />
            </div>}
            {expandedZone === zone && (
              <div className="accordion-details">
                {Object.entries(stops).filter(([stop]) => stop !== 'stop0').map(([stop, assets]) => (
                  <div key={stop} className="accordion">
                    <div className="accordion-summary" onClick={() => handleAccordionToggleStop(stop)}>
                      <h4>{stop}</h4>
                      <FontAwesomeIcon
                        icon={expandedStop === stop ? faChevronUp : faChevronDown}
                        style={{ marginLeft: 'auto' }}
                      />
                    </div>
                    {expandedStop === stop && (
                      <div className="accordion-details">
                        {Object.entries(assets).map(([asset, value]) => (
                          <label key={asset} className="asset-label">
                            <input
                              type="checkbox"
                              checked={!!selectedAssets[zone]?.[stop]?.assets[asset]}
                              onChange={() => handleAssetToggle(zone, stop, asset, value)}
                            />
                            {asset}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="confirm-button-container">
        <button onClick={handleConfirm} disabled={missionName === '' || submitted === true}>{submitted ? 'Submitted' : 'Confirm'}</button>
      </div>
    </div>
  );
};

export default AssetSelectionForm;

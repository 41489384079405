import { 
    memo, 
    useEffect, 
    useState, 
    MouseEventHandler 
} from 'react';
import { Row, Col } from 'react-bootstrap';
import { useFetch } from '../../services/Requests/useFetch';
import { StopTravelControlBanner } from './StopTravelControlBanner';
import { ROBOT_URL, ROS_PORT } from '../../constants/constants';
import { Subscriber } from '../../services/Ros/Subscriber';
import { GPS_TOPIC, STATE } from '../../constants/topics';
import { StopTravelMap } from './StopTravelMap';
import { useRobotGPS } from '../../services/Ros/useRobotGPS';
import { RobotState } from '../../core/Messages/RobotStateMessage';
import { useRealtimeUpdates } from '../../services/Requests/websocket';

type LastRun = string | null | undefined;

export interface StopData {
    stop: string;
    pathname: string;
    next: string;
    next_pathname: number;
    gps: number[];
    zone: string;
}

export interface IStopInfo {
    stops: StopData[];
}

export interface IZoneInfo  {
    zones: IStopInfo[];
}

interface IRunMission {
    t: string;
}

const options: RequestInit = {
    method: "GET"
}

interface IUpdateMessage { 
    // data: string;
    id : number;
    prog : number;
    length: number;
    run : boolean;
    start_time? : LastRun;
}

export interface IMissionInfo {
    length : number;
    prog : number;
    run : boolean;
    last_run? : LastRun;
    start_time?: LastRun;
}

interface StopTravelBodyProps {
    initialActiveIndex?: string;
    initialMissionInfo?: IStopInfo;
    initialLaunched?: boolean;
    setInitialActiveIndex: (value: string) => void;
    setInitialMissionInfo: (value: IStopInfo) => void;
    setInitialLaunched: (value: boolean) => void;
}

// Haversine formula to calculate the distance between two GPS points
interface GPSPoint {
    lat: number;
    long: number;
}

function haversineDistance(point1: GPSPoint, point2: GPSPoint): number {
    const toRadians = (degrees: number) => degrees * (Math.PI / 180);

    const R = 6371e3; // Earth's radius in meters
    const φ1 = toRadians(point1.lat);
    const φ2 = toRadians(point2.lat);
    const Δφ = toRadians(point2.lat - point1.lat);
    const Δλ = toRadians(point2.long - point1.long);

    const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) *
        Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // in meters

    return distance;
}

function arePointsClose(point1: GPSPoint, point2: GPSPoint, threshold: number): boolean {
    const distance = haversineDistance(point1, point2);
    return distance <= threshold;
}

function getClosestStop(robotPos: GPSPoint,stopList: IStopInfo): StopData | null{
    let returnStop = null;
    let distance=3;
    stopList.stops.forEach(stopData => {
        const gpsPoint: GPSPoint = { lat: stopData.gps[0], long: stopData.gps[1] };
        if (arePointsClose(robotPos,gpsPoint,2.5)){
            const checkDistance = haversineDistance(robotPos,gpsPoint)
            if (checkDistance<distance){
                distance = checkDistance;
                returnStop = stopData;
            }
        }
    });

    console.log(distance,returnStop);
    return returnStop;
}

export const StopTravelBodyMemo = ({
    initialActiveIndex = '',
    initialMissionInfo = { stops: [] },
    initialLaunched = false,
    setInitialActiveIndex,
    setInitialMissionInfo,
    setInitialLaunched
}: StopTravelBodyProps) => {
    const fetchMissionPlanData = useFetch<StopData[]>('/api/missions/get_stops_data', options);
    const runMissionPlanHome = useFetch<IRunMission>('/api/missions/stop_to_home', options);
    const runGoToStop = useFetch<IRunMission>('/api/missions/go_to_stop', options);

    const { ws,lastMessage } = useRealtimeUpdates();                                                         //Get the live updates websocket from the context provider.
    const socket = ws

    const [activeIndex, setActiveIndex] = useState<string>(initialActiveIndex);
    const [missionInfo, setMissionInfo] = useState<IStopInfo>(initialMissionInfo);
    const [traversalInfo, setTraversalInfo] = useState<IMissionInfo>({length: lastMessage?.data.length, prog : lastMessage?.data.prog, run : lastMessage?.data.run})
    const [activeStop, setActiveStop] = useState<StopData>();
    const [currentStop, setCurrentStop] = useState<StopData | null>();
    const [homeStop, setHomeStop] = useState<StopData>();
    const [launched, setLaunched] = useState<boolean>(initialLaunched);
    const [pointsAreClose, setPointsAreClose] = useState<boolean>(false)
    const [robotIsHome, setRobotIsHome] = useState<boolean>(false)
    const robotGPS = useRobotGPS()

    // console.log(currentStop)

    const handleSelected = (id: string) => {
        setActiveIndex(id);
        setInitialActiveIndex(id);
        
        // Split the id into zone and stop
        const [zone, stop] = id.split('/');
    
        // Find the matching stop data
        const selectedStop = missionInfo.stops.find((stopData) => 
            stopData.zone === zone && stopData.stop === stop
        );
    
        // If found, set the activeStop
        if (selectedStop) {
            setActiveStop(selectedStop);
        }
    
        console.log(selectedStop);
    }
    
    const handleRunHome: MouseEventHandler = (_e) => {
        if (currentStop) {
            const info = [currentStop.zone,currentStop.stop];
            runMissionPlanHome.get([
                { param: 'mission', value: 333 },
                { param: 'host', value: ROBOT_URL },
                { param: 'port', value: ROS_PORT },
                { param: 'stop', value: info[1] },
                { param: 'zone', value: info[0] }
            ]);
        }
        setLaunched(true);
        setInitialLaunched(true);
    }

    const handleRunStop: MouseEventHandler = (_e) => {
        if (activeIndex !== ''&& currentStop) {
            const info = activeIndex.split('/');
            const zones = `${currentStop.zone},${info[0]}`
            const stops = `${currentStop.stop},${info[1]}`
            // const zones = `zone2,${info[0]}`
            // const stops = `stop3,${info[1]}`
            runGoToStop.get([
                { param: 'mission', value: 333 },
                { param: 'host', value: ROBOT_URL },
                { param: 'port', value: ROS_PORT },
                { param: 'stop', value: stops },
                { param: 'zone', value: zones }
            ]);
        }
        setLaunched(true);
        setInitialLaunched(true);
    }

    useEffect(() => {
        fetchMissionPlanData.get();

        return () => {
            fetchMissionPlanData.abortRequest && fetchMissionPlanData.abortRequest();
        }
    }, []);

    useEffect(() => {
        const { state: { status, data } } = fetchMissionPlanData;

        if (status === 'fetched' && data && data.length > 0) {
            console.log(data)
            console.log(data[0])
            setHomeStop(data[0])
            setMissionInfo({ stops: data });
            setInitialMissionInfo({ stops: data });
        }
    }, [fetchMissionPlanData.state]);

    useEffect(() => {
        setActiveIndex(initialActiveIndex);
        setMissionInfo(initialMissionInfo);
        setLaunched(initialLaunched);
    }, [initialActiveIndex, initialMissionInfo, initialLaunched]);

    const handleMarkerClick = (e: StopData) => {
        setActiveStop(e);
        setActiveIndex(`${e.zone}/${e.stop}`);
        setInitialActiveIndex(`${e.zone}/${e.stop}`);
    }

    useEffect(() => {
        if (robotGPS && missionInfo){
            const robotPos: GPSPoint = { lat: robotGPS.lat, long: robotGPS.long }; // Example GPS point
            setCurrentStop(getClosestStop(robotPos,missionInfo))
        }

        if (robotGPS && currentStop) {
            const thresholdDistance = 2.5; // in meters
            const gpsPoint1: GPSPoint = { lat: currentStop.gps[0], long: currentStop.gps[1] }; // Example GPS point
            const gpsPoint2: GPSPoint = { lat: robotGPS.lat, long: robotGPS.long }; // Example GPS point
            setPointsAreClose(arePointsClose(gpsPoint1, gpsPoint2, thresholdDistance));
        }
        

        // if (robotGPS && activeStop) {
        //     const thresholdDistance = 2.5; // in meters
        //     const gpsPoint1: GPSPoint = { lat: activeStop.gps[0], long: activeStop.gps[1] }; // Example GPS point
        //     const gpsPoint2: GPSPoint = { lat: robotGPS.lat, long: robotGPS.long }; // Example GPS point
        //     // setPointsAreClose(arePointsClose(gpsPoint1, gpsPoint2, thresholdDistance));
        // }

        if (robotGPS && homeStop){
            const thresholdDistance = 2.5;
            const gpsPoint1: GPSPoint = { lat: homeStop.gps[0], long: homeStop.gps[1] }; // Example GPS point
            const gpsPoint2: GPSPoint = { lat: robotGPS.lat, long: robotGPS.long }; // Example GPS point
            setRobotIsHome(arePointsClose(gpsPoint1, gpsPoint2, thresholdDistance));
        }
        

    }, [robotGPS, activeStop]);

     /**
     * Receives live update messages from the NOTIFY function on the postgres database for mission plans.
     * Listens for a message containing id of the mission plan, current progress, and whether the mission is still running.
     * @param message The message received by the websocket
     */
     const onSocketMessage = (message : MessageEvent) => {
        const data : IUpdateMessage = JSON.parse(message.data);
        console.log(message.data)
        // if(data.id === activeIndex){
        setTraversalInfo({...traversalInfo, prog : data.prog, run : data.run, length: data.length,start_time:data.start_time});
        console.log(traversalInfo)
        // }
    };
    useEffect(()=>{
        if (lastMessage){
            onSocketMessage(lastMessage);
            console.log(` Last Message:${lastMessage.data}`)
        }
        
        
    },[lastMessage]);
    

    /**
     * Runs when the notification websocket, active index or missionInfo object are changed.
     * Responsible for adding a websocket message listener to listen for the live updates from the database.
     */
    useEffect(() => {

        socket.addEventListener('message', onSocketMessage);

        //Cleanup. Removes listener when this component is destroyed.
        return () => {
            socket.removeEventListener('message', onSocketMessage);
        }

    }, [socket, activeIndex, missionInfo]);

    const handleRobotStateChange = (state: string) => {
        if (state === "READY" && launched && traversalInfo.run !== true ) {
            setLaunched(false);
            setInitialLaunched(false);
        }
        if (state === "MANUAL") {
            setLaunched(true);
            setInitialLaunched(true);
        }
    }

    return (
        <Row className='h-100'>
            <Col lg={9} className="ps-0">
                <Subscriber name={GPS_TOPIC.name} type={GPS_TOPIC.type} rate={GPS_TOPIC.throttle}>
                    <StopTravelMap missionInfo={missionInfo} onMarkerClick={handleMarkerClick} activeIndex={activeIndex} />
                </Subscriber>
                <Subscriber name={STATE.name} type={STATE.type} rate={STATE.throttle}>
                    <RobotState name="robot_state" onStateChange={handleRobotStateChange} />
                </Subscriber>
            </Col>
            <Col lg={3} className="mt-3 mb-3 path-planning-column">
                <StopTravelControlBanner
                    currentStop={currentStop} 
                    onSelect={handleSelected} 
                    onRun={handleRunHome}
                    onRunStop={handleRunStop}
                    selectedValue={activeIndex}
                    running={launched}
                    safe={pointsAreClose}
                    home={robotIsHome}    
                    progress={traversalInfo.prog}       
                />
            </Col>
        </Row>
    );
}

export const StopTravelBody = memo(StopTravelBodyMemo);

import { createContext, useContext, FC } from 'react';
import ROSLIB from 'roslib';

interface IRosProvider {

}

const RosContext = createContext<any>({});

const RosInitialState : ROSLIB.Ros = new ROSLIB.Ros({});

export const RosProvider : FC<IRosProvider> = ({children}) => {

    const ROS = RosInitialState;

    return (
        <RosContext.Provider value={ROS}>
            {children}
        </RosContext.Provider>
    );
}

export const useRos = () => {
    const ROS = useContext(RosContext);
    if(ROS === undefined){
        throw new Error('useRos must be used within ros provider');
    }
    return ROS;
}



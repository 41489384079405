import React, { useEffect } from 'react';
import userManager from './userManager';
import { AUTHREQ } from '../../../constants/constants';

const AuthCallback = () => {
  
  useEffect(() => {
    console.log("Attempting to process the sign-in callback...");
    if (AUTHREQ){
      userManager.signinRedirectCallback()
        .then((user) => {
          console.log("Callback processed, user:", user);
          window.location.href = user.state.targetUrl || '/';
        })
        .catch(err => {
          console.error("Error in sign-in callback:", err);
        });
    }else{
      console.log(`Authentication required set to ${AUTHREQ}`)

    }
  }, []);
  
  
  

  return <div>Logging in...</div>;
};

export default AuthCallback;

import { FC } from 'react';
import { Container } from 'react-bootstrap';
// import { useToggle } from '../../services/General/useToggle';
// import useKeyPress from '../../services/Controls/useKeyPress';

interface VideoComponentOverlayProps { 

}

export const VideoComponentOverlay: FC<VideoComponentOverlayProps> = ({children}) => {

    // const [ show, toggleShow ] = useToggle();
    const show = true;

    // useKeyPress('f', toggleShow);

    return(
        <Container fluid className={`video-component-overlay ${show ? "show-overlay" : "hide-overlay"}`}>
            { children }
        </Container>
    );
}


import React, { createContext, useState, useContext, useEffect } from 'react';
import { OPERATOR_STATUS } from '../../../constants/constants';

// Create a context to hold the Operator status
const OperatorContext = createContext();

// Custom hook to use the Operator context
export const useOperator = () => useContext(OperatorContext);

// Provider component that holds the state of IS_Operator
export const OperatorProvider = ({ children }) => {
  const [isOperator, setIsOperator] = useState(OPERATOR_STATUS);
  console.log(isOperator)

  return (
    <OperatorContext.Provider value={{ isOperator, setIsOperator }}>
      {children}
    </OperatorContext.Provider>
  );
};

import React, { useEffect, useState } from 'react';
import { useFetch } from '../../services/Requests/useFetch';
import '../../css/MissionScheduler.css';

interface SchedulerFormProps {
  missionId: string;
  onScheduleMission: (submit: boolean) => void;
  cancel: boolean;
}

const options: RequestInit = {
  method: "GET"
}

const postOptions: RequestInit = {
  method: "POST",
  headers: {
    'Content-Type': 'application/json'
  }
}

interface MissionSchedule {
  mission_id: string;
  name: string;
  time: string;
  daysOfWeek: string[];
}


const MissionSchedulerForm: React.FC<SchedulerFormProps> = ({ missionId, onScheduleMission, cancel }) => {
  const [newTime, setNewTime] = useState<string>('');
  const [newDaysOfWeek, setNewDaysOfWeek] = useState<string[]>([]);
  const [missionSchedules, setMissionSchedules] = useState<MissionSchedule[]>([]);
  const scheduleMission = useFetch('/api/missions/schedule_mission', postOptions);

  const mission_schedule_plans = useFetch<MissionSchedule[]>('/api/missions/mission_schedule', options);

  useEffect(() => {
    const fetchMissionSchedules = async () => {
      await mission_schedule_plans.get();
    };
    fetchMissionSchedules();
    
    return () => {
      if (mission_schedule_plans.abortRequest) {
        mission_schedule_plans.abortRequest();
      }
    };
  }, []);

  useEffect(() => {
    if (cancel===true) {
      const fetchMissionSchedules = async () => {
        await mission_schedule_plans.get();
      };
      fetchMissionSchedules();

      // return () => {
      //   if (mission_schedule_plans.abortRequest) {
      //     console.log('abort')
      //     mission_schedule_plans.abortRequest();
      //   }
      // };
    }
  }, [cancel]);


  useEffect(() => {
    const { state: { status, data } } = mission_schedule_plans;
    if (status === 'fetched' && data && data.length > 0) {
      setMissionSchedules(data);
    }
    
  }, [mission_schedule_plans.state]);

  const handleUpdate = () => {
    const fetchMissionSchedules = async () => {
      await mission_schedule_plans.get();
    };
    fetchMissionSchedules();
  }

  const handleDayChange = (day: string) => {
    setNewDaysOfWeek(prevDays =>
      prevDays.includes(day) ? prevDays.filter(d => d !== day) : [...prevDays, day]
    );
  };
  const getTimeSlotIndex = (time: string) => {
    const [hours, ] = time.split(':').map(Number)
  //   return (hours * 2) + (minutes >= 30 ? 1 : 0);
    const output = `${hours.toString().padStart(2, '0')}:00`
    return (output);
  };

  const isTimeSlotAvailable = (time: string, daysOfWeek: string[]): boolean => {
    return !missionSchedules.some(schedule =>
      getTimeSlotIndex(schedule.time) === time && daysOfWeek.some(day => schedule.daysOfWeek.includes(day))
    );
  };

  const handleScheduleMission = async () => {
    if (!isTimeSlotAvailable(newTime, newDaysOfWeek)) {
      alert('Time slot is already taken.');
      return;
    }
    const scheduleData = { mission_id: missionId, time: newTime, daysOfWeek: newDaysOfWeek };
    await scheduleMission.post(scheduleData);
    onScheduleMission(true);
    handleUpdate();
  
  };
  
  return (
    <div className="new-schedule">
      <h3>Schedule a New Mission</h3>
      <input
        type="time"
        value={newTime}
        onChange={e => setNewTime(e.target.value)}
      />
      <div className="days-of-week">
        {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map(day => (
          <label key={day}>
            <input
              type="checkbox"
              checked={newDaysOfWeek.includes(day)}
              onChange={() => handleDayChange(day)}
            />
            {day}
          </label>
        ))}
      </div>
      <button onClick={handleScheduleMission}>Schedule Mission</button>
    </div>
  );
};

export default MissionSchedulerForm;

import { useState, useEffect } from 'react';

export const useMobileCheck = () => {

    const [ isMobile, setIsMobile ] = useState<boolean>(false);

    //console.log(isMobile);

    useEffect(() => {
        let hasTouchScreen = false;
        if("maxTouchPoints" in navigator){
            hasTouchScreen = navigator.maxTouchPoints > 0;
        } else {
            const mQ = matchMedia("(pointer:coarse)");
            if(mQ && mQ.media === "(pointer:coarse)"){
                hasTouchScreen = !!mQ.matches;
            } else if ("orientation" in window){
                hasTouchScreen = true;
            } else {
                var userAgent = navigator.userAgent;
                hasTouchScreen =           
                /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(userAgent) ||
                /\b(Android|Windows Phone|iPad|iPod)\b/i.test(userAgent);
            }
        }
        
        hasTouchScreen = false

        if(hasTouchScreen){
            // console.log("Is Mobile");
            setIsMobile(true);
        } else {
            // console.log("Is not mobile");
            setIsMobile(false);
        }
    }, []);

    return isMobile;
}
import {
    useRef, 
    useMemo,
    useEffect,
    EffectCallback,
    DependencyList
} from 'react';

import { dequal } from './dequal';

function checkDeps(deps: DependencyList) {
    if (!deps || !deps.length) {
      throw new Error(
        'useDeepCompareEffect should not be used with no dependencies. Use React.useEffect instead.',
      )
    }
    if (deps.every(isPrimitive)) {
      throw new Error(
        'useDeepCompareEffect should not be used with dependencies that are all primitive values. Use React.useEffect instead.',
      )
    }
  }
  
function isPrimitive(val: unknown) {
    return val == null || /^[sbn]/.test(typeof val)
}

function useDeepCompareMemoize<T>(value : T) {
    const ref = useRef<T>(value);
    const signalRef = useRef<number>(0);

    if(!dequal(value, ref.current)){
        ref.current = value;
        signalRef.current += 1;
    }

    return useMemo(() => ref.current, [signalRef.current]);
}

export default function useDeepCompareEffect( callback : EffectCallback, dependencies : DependencyList) : void {
    if(process.env.NODE_ENV !== 'production'){
        checkDeps(dependencies);
    }

    return useEffect(callback, useDeepCompareMemoize(dependencies))
} 


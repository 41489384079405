import React from 'react';
import '../../../css/robot-status.css';

interface StatusContainerProps {
    style? : object;
    className? : string;
};

export const StatusContainer: React.FC<StatusContainerProps> = ({ style, children, className }) => {
    return(
        <div className={`${className} mdc-elevation--z24 rounded-2-5 justify-content-between d-flex ps-3 pe-3 align-items-center`} style={style}>
            { children }
        </div>
    );
};

import { FC } from 'react';

import StandardContainerProps from "../../../interfaces/StandardContainerProps";

export const VitalContainer: FC<StandardContainerProps> = ({style, children}) => {
    return (
        <div className="d-flex justify-content-between" style={style}>
            { children }
        </div>
    );
}
import { useEffect, useState } from 'react';
import WidgetCard from './WidgetCard';
import '../../css/RecordingWidget.css';
import { Subscriber, useMsg } from '../../services/Ros/Subscriber';
import { GPS_TOPIC } from '../../constants/topics';
import { useFetch } from '../../services/Requests/useFetch';
import { useScreenSize } from '../../services/General/ScreenResolutionProvider';
import { ROBOT_URL, ROS_PORT } from '../../constants/constants';
import { useRealtimeUpdates } from '../../services/Requests/websocket';
import PTZService from '../../services/Video/PTZService';


interface RouteAsset {
    stop: number;
    asset: string;
}

interface IRecordMission {
    t: string;
}

interface IRunMission {
    t: string;
}

const options: RequestInit = {
    method: "GET"
}

const postOptions: RequestInit = {
    method: "POST"
}

interface zoneRequest{
    zone: string;
}

interface IUpdateMessage { 
    // data: string;
    id : number;
    prog : number;
    length: number;
    run : boolean;
}

interface IGPSMessage {
    altitude?: number;
    header?: {
      frame_id: string;
      seq: number;
      stamp: {
        secs: number;
        nsecs: number;
      };
    };
    latitude: number;
    longitude: number;
    position_covariance?: Array<number>;
    position_covariance_type?: number;
    status?: {
      status: number;
      service: number;
    };
}

interface RecordingWidgetProps {
    isManualModeActive: boolean;
}

export const RecordingWidget = ({ isManualModeActive }: RecordingWidgetProps) => {
    const { width } = useScreenSize();
    const [recordingState, setRecordingState] = useState(false);
    const [currentStop, setCurrentStop] = useState(1);
    const [nextStop, setNextStop] = useState(2);
    const [isMoving, setIsMoving] = useState(false);
    const [assetName, setAssetName] = useState('');
    const [routeAssets, setRouteAssets] = useState<RouteAsset[]>([]);
    const [pathName, setPathName] = useState('');
    const [isRecordingInProgress, setIsRecordingInProgress] = useState(false);
    const [isOperating, setIsOperating] = useState(false);
    const [isEditable, setisEditable] = useState<boolean>(false)
    const [isEditing, setisEditing] = useState<boolean>(false)
    const [isTestButtonClicked, setIsTestButtonClicked] = useState(false); // New state for tracking test button click
    const [avaliableZones, setAvaliableZones] = useState<string[]>([]);
    const [avaliableStops, setAvaliableStops] = useState<string[]>([]);
    const [selectedStop, setSelectedStop] = useState(''); // New state for selected stop

    const gpsFix = useMsg() as IGPSMessage;
    // const socket = useRealtimeUpdates();   
    const { ws} = useRealtimeUpdates();                                                         //Get the live updates websocket from the context provider.
    const socket = ws

    const recordData = useFetch<IRecordMission>('/api/missions/record', options);
    const setAsset = useFetch<IRecordMission>('/api/missions/set_asset', options);
    const testMissionPlan = useFetch<IRunMission>('/api/missions/test_stops', options);
    const cleardata = useFetch<zoneRequest>('/api/missions/remove_zone', postOptions);
    const zoneStops = useFetch<string[]>('/api/missions/get_stops', options); // Gets a list of available stops when given {zone: zone} params
    const getZones = useFetch<string[]>('/api/missions/get_zones', options); // Gets a list of available zones
    const runGoToHome = useFetch<IRunMission>('/api/missions/stop_to_home', options);
    const runGoToStop = useFetch<IRunMission>('/api/missions/go_to_stop', options);


    let position = 'widget-bottom-left';

    if (width <= 1024) {
        position = 'widget-bottom-left';
    }

    const handleZoneStops = () => {
        zoneStops.get([{ param: 'zone', value: pathName }]);     // Performs a get request with the selected zone as a query param.
        console.log('Got Stops for zone ', pathName);
        
    };

    const handleZeroPTZ = async () => {
        try {
            await PTZService.gotoPreset('2');
            console.log('PTZ went to Preset ' + '2');
            await PTZService.gotoPreset('1');
            console.log('PTZ went to Preset ' + '1');
        } catch (error) {
            console.error('Failed to go to Preset', error);
        }
    };

    const handleGetZones = () => {
        getZones.get();     // Performs a get request to get available zones.
        console.log('Got Zones');
    };

    const handleRunGoToStop = async (value: string) =>{
        const info = `stop${currentStop},${value}`
        await runGoToStop.get([
            { param: 'mission', value: 333 },
            { param: 'host', value: ROBOT_URL },
            { param: 'port', value: ROS_PORT },
            { param: 'stop', value: info },
            { param: 'zone', value: pathName }
        ]);
    }

    const handleRunGoToHome = async () => {
        const info = `stop${currentStop}`
        await runGoToHome.get([
            { param: 'mission', value: 333 },
            { param: 'host', value: ROBOT_URL },
            { param: 'port', value: ROS_PORT },
            { param: 'stop', value: info},
            { param: 'zone', value: pathName }
        ]);
        setCurrentStop(1)

    }

    /**
     * Receives live update messages from the NOTIFY function on the postgres database for mission plans.
     * Listens for a message containing id of the mission plan, current progress, and whether the mission is still running.
     * @param message The message received by the websocket
     */
    const onSocketMessage = (message : MessageEvent) => {
        const data : IUpdateMessage = JSON.parse(message.data);
        console.log(data)
        setIsMoving(data.run)
    

    };

    /**
     * Runs when the notification websocket, active index or missionInfo object are changed.
     * Responsible for adding a websocket message listener to listen for the live updates from the database.
     */
    useEffect(() => {

        socket.addEventListener('message', onSocketMessage);

        //Cleanup. Removes listener when this component is destroyed.
        return () => {
            socket.removeEventListener('message', onSocketMessage);
        }

    }, [socket]);

    useEffect(() => {
        const { status, data } = zoneStops.state;

        if (status === 'fetched') {
            if (data) {
                // Set data as available stops minus the final stop
                setAvaliableStops(data.slice(0, -1));
                console.log(data.slice(0, -1))
            }
        }
    }, [zoneStops.state.data]);

    useEffect(() => {
        const { status, data } = getZones.state;

        if (status === 'fetched') {
            if (data) {
                // Set data as available zones minus zone0
                console.log(data)
                setAvaliableZones(data.filter((zone: string) => zone !== 'zone0'));
            }
        }
    }, [getZones.state.data]);

    const handleStartRecording = () => {
        setRecordingState(true);
        setIsRecordingInProgress(true); // Disable path name input
        setIsMoving(true);
        setNextStop(currentStop + 1);
        setSelectedStop(`stop${currentStop}`);

        const path = `/${pathName}_${currentStop}_${currentStop + 1}`;
        const paths = `record,/${path}`;
        const stops = `stop${currentStop},${path},stop${currentStop + 1}`;
        console.log(paths);
        recordData.get([{ param: 'mission', value: 1 },
                        { param: 'host', value: ROBOT_URL },
                        { param: 'port', value: ROS_PORT },
                        { param: 'path_name', value: paths },
                        { param: 'stops', value: stops },
                        { param: 'zone', value: pathName }]);     // Performs a get request with the mission plan id as a query param.
        console.log('Bus route recording started');
    };

    const handleFinishRecording = () => {
        setRecordingState(false);
        console.log(gpsFix);
        const path = `/${pathName}_${currentStop}_${currentStop + 1}`;
        const paths = `save,/${path}`;
        console.log(paths);
        const stops = `stop${currentStop},${path},stop${currentStop + 1}`;
        recordData.get([{ param: 'mission', value: 1 },
                        { param: 'host', value: ROBOT_URL },
                        { param: 'port', value: ROS_PORT },
                        { param: 'path_name', value: paths },
                        { param: 'stops', value: stops },
                        { param: 'zone', value: pathName }]);
        setCurrentStop(nextStop);
        setSelectedStop(`stop${nextStop}`);
        setIsMoving(false);
        console.log('Bus route recording finished');
        handleZeroPTZ();
    };

    const handleSubmitAsset = () => {
        setRouteAssets((prevAssets) => [...prevAssets, { stop: parseInt(selectedStop.substring(4)), asset: assetName }]);
        console.log(`Asset '${assetName}' submitted for ${selectedStop}`);
        const submitStop = selectedStop;
        setAsset.get([{ param: 'mission', value: 1 },
                      { param: 'host', value: ROBOT_URL },
                      { param: 'port', value: ROS_PORT },
                      { param: 'asset', value: assetName },
                      { param: 'stop', value: submitStop },
                      { param: 'zone', value: pathName }]);
        console.log('Testing recorded path', routeAssets);
        setAssetName('');
        // handleZeroPTZ();
    };

    const handleStartOperating = () => {
        setIsOperating(true);
        setSelectedStop("stop1")
        setIsRecordingInProgress(true); // Enable path name input
        console.log('Recording started');
    };

    const handleEndRecording = () => {
        setIsOperating(false);
        setRecordingState(false);

        setCurrentStop(1);
        setNextStop(2);
        setRouteAssets([]);
        setIsRecordingInProgress(false); // Enable path name input
        console.log('Recording ended and reset to Bus Stop 1');
    };

    const handleTestPath = () => {
        setIsTestButtonClicked(true); // Indicate that the button has been pressed
        testMissionPlan.get([{ param: 'mission', value: 1 },
                             { param: 'host', value: ROBOT_URL },
                             { param: 'port', value: ROS_PORT },
                             { param: 'path_name', value: pathName },
                             { param: 'zone', value: pathName }]);
        console.log('Testing recorded path', routeAssets);
    };

    const handleAbort = () => {
        cleardata.post({zone: pathName});
        console.log('Removing Zone', pathName);
    };

    const handleEditZone = () => {
        // Implement handle function for editing zone here
        console.log('Editing zone', pathName);
        setisEditing(true)
        handleZoneStops()
    };

    const handleFinishEdit = () => {
        // Implement handle function for finishing edit here
        setisEditing(false)
        if (currentStop !== 1 ){
            handleRunGoToHome()
        }
        console.log('Finished editing zone', pathName);
    };

    const handleStopChange = async (value: string) =>{
        console.log("Moving Between Stops")
        console.log(value,currentStop)
        if (value === 'stop1' && currentStop !== 1){
            console.log('Going Home')
            await handleRunGoToHome();
            
            setNextStop(parseInt(value.substring(4)));
        }else if (parseInt(value.substring(4))> currentStop){ //Selected Stop is before current stop
            console.log(`Going from ${currentStop} to ${value}`);
            await handleRunGoToStop(value);
            setNextStop(parseInt(value.substring(4)));
        }else if ((parseInt(value.substring(4))< currentStop)){
            console.log(`Going from ${currentStop} to ${value} via Home`)
            await handleRunGoToHome()
            // while (isMoving);
            await handleRunGoToStop(value)
            setNextStop(parseInt(value.substring(4)))
        }else {
            console.log("Already @ Stop")
        }
        setSelectedStop(value)
    }

    useEffect(() => {
        if (isMoving === false && isEditing){
            console.log("Has Stopped")
            setCurrentStop(nextStop)
        }
    }, [isMoving]);

    useEffect(() => {
        handleGetZones(); // Fetch available zones on component mount
    }, []);

    // const isZoneExisting = avaliableZones.includes(pathName);

    return (
        <Subscriber name={GPS_TOPIC.name} type={GPS_TOPIC.type} rate={GPS_TOPIC.throttle}>
            <WidgetCard position={position}>
                <WidgetCard.Header>
                    Recording Control
                </WidgetCard.Header>
                <WidgetCard.Body>
                    <div className="recording-control-box">
                        <input
                            type="number"
                            className="form-control"
                            value={pathName.substring(4)}
                            onChange={(e) => {
                                const zone = `zone${e.target.value}`;
                                if (zone !== 'zone'){
                                    setPathName(zone);
                                    console.log(zone)
                                    console.log(avaliableZones)
                                    if (avaliableZones.includes(zone)) {
                                        handleZoneStops();
                                        setisEditable(true)
                                    }else{
                                        setisEditable(false)
                                    }
                                }else{
                                    setPathName('')
                                }
                            }}
                            placeholder="Enter Zone Number"
                            min="1"
                            max="255"
                            disabled={isRecordingInProgress || isEditing} // Disable input during recording
                        />
                        {isEditable ? 
                        <button
                            type="button"
                            className={`form-control btn ${isEditing ? 'btn-danger' : 'btn-success'  }`}
                            onClick={isEditing ? handleFinishEdit : handleEditZone  }
                            disabled={!pathName || isManualModeActive || isMoving}
                        >
                            {isEditing ? 'Finish Edit' : 'Edit Zone'}
                        </button>
                        :
                        <button
                            type="button"
                            className={`form-control btn ${isOperating ? 'btn-danger' : 'btn-success'}`}
                            onClick={isOperating ? handleEndRecording : handleStartOperating}
                            disabled={!pathName}
                        >
                            {isOperating ? 'End Recording' : 'Record Zone'}
                        </button>}
                        {isEditable ? 
                            <select className="form-control" value={selectedStop} onChange={(e) => handleStopChange(e.target.value)} disabled={!isEditing || isMoving}>
                            <option value="">Select Stop</option>
                            {avaliableStops.map((stop, index) => (
                                <option key={index} value={stop}>
                                    {stop}
                                </option>
                            ))}
                            </select>
                        :
                        <button
                            type="button"
                            className={`form-control btn ${recordingState ? 'btn-danger' : 'btn-success'}`}
                            onClick={recordingState ? handleFinishRecording : handleStartRecording}
                            disabled={!pathName || !isOperating} // Disable button if path name is empty or recording is in progress
                        >
                            {recordingState ? 'Finish Bus Route' : 'Start Bus Route'}
                        </button>}
                        <div className="current-stop-display">
                            {isMoving ? (
                                <span>Moving from stop {currentStop} to stop {nextStop}</span>
                            ) : (
                                <span>Current Bus Stop: {currentStop}</span>
                            )}
                        </div>
                        <div className="asset-input-box">
                            <input
                                type="text"
                                className="form-control"
                                value={assetName}
                                onChange={(e) => setAssetName(e.target.value.replace(/\s+/g, ''))}
                                placeholder="Enter asset name"
                                disabled={!pathName || (!isOperating && !isEditing) || recordingState || isMoving}
                            />
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={handleSubmitAsset}
                                disabled={!pathName || (!isOperating && !isEditing) || recordingState || isMoving}
                            >
                                Submit Asset
                            </button>
                        </div>
                        <button
                            type="button"
                            className="form-control btn btn-info"
                            onClick={handleTestPath}
                            disabled={!pathName || isManualModeActive || isEditing || isMoving || isTestButtonClicked || (currentStop !== 1)} // Disable based on manual mode and button click state
                        >
                            {isTestButtonClicked ? 'Testing Path...' : 'Test Recorded Path'}
                        </button>
                        <button
                            type="button"
                            className="form-control"
                            style={{ backgroundColor: 'red', color: 'white' }}
                            onClick={handleAbort}
                            disabled={!pathName || isMoving || (isOperating && isEditing) }
                        >
                            Remove Zone
                        </button>
                    </div>
                </WidgetCard.Body>
            </WidgetCard>
        </Subscriber>
    );
};

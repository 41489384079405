
/**
 * Parses a UTC date to local time. 
 * @param date a date string in UTC format with a z appended to the end.
 */
export const parseUtcToLocal = (date : string) => {
    let localDate = new Date(date);
    const yyyy : number | string = localDate.getFullYear();
    let mm : number | string = localDate.getMonth() + 1;
    let dd : number | string = localDate.getDate();
    let hh : number | string = localDate.getHours();
    let min : number | string = localDate.getMinutes();

    if(dd < 10) dd = '0' + dd;
    if(mm < 10) mm = '0' + mm;
    if(hh < 10) hh = '0' + hh;
    if(min < 10) min = '0' + min;

    return dd + '/' + mm + '/' + yyyy + ' ' + hh + ':' + min;
    
}

export const convertTimestampToNumber = (timestamp : string) => {
    return new Date(timestamp).getTime();
}

import { useEffect, useState } from "react";
import { Topic, Message } from "roslib";

import { GPS_TOPIC } from "../../constants/topics";
import { useRos } from "../../services/Ros/utils/rosProvider";
import { subscribe, buildTopicOptions, unsubscribe } from "../../services/Ros/utils/rosTopicCommands";

interface IGPSMessage {
  altitude?: number;
  header?: {
    frame_id: string;
    seq: number;
    stamp: {
      secs: number;
      nsecs: number;
    };
  };
  latitude: number;
  longitude: number;
  position_covariance?: Array<number>;
  position_covariance_type?: number;
  status?: {
    status: number;
    service: number;
  };
}

export const useRobotGPS = () => {
  const ROS = useRos();
  const [robotGPS, setRobotGPS] = useState<{ lat: number, long: number } | null>(null);

  useEffect(() => {
    let topic: Topic | undefined;

    const callback = (msg: Message) => {
      const gpsMsg = msg as IGPSMessage;
      setRobotGPS({ lat: gpsMsg.latitude, long: gpsMsg.longitude });
    };

    topic = subscribe(
      buildTopicOptions(ROS, GPS_TOPIC.name, GPS_TOPIC.type, GPS_TOPIC.throttle),
      callback
    );

    return () => {
      if (topic) {
        unsubscribe(topic);
      }
    };
  }, [ROS]);

  return robotGPS;
};

import { Topic, Message } from "roslib";
import { accurateIntervalTimer } from "../../../utilities/utiltyFunctions";
import { publish } from "../utils/rosTopicCommands";

/**
 * Sequence number for TwistStamped messages
 */
// let sequenceNumber = 0;

/**
 * Drive the robot by publishing Twist or TwistStamped messages.
 * @param topic - The ROS topic to publish to.
 * @param linear - Linear velocities (x, y, z).
 * @param angular - Angular velocities (x, y, z).
 * @param stamped - Whether to publish a TwistStamped message. Defaults to false.
 */
export const drive = (
    topic: Topic,
    linear: { x: number; y: number; z: number },
    angular: { o: number; a: number; t: number },
    stamped: boolean = true
): void => {
    const msg = buildMessage(linear, angular, stamped);
    console.log(topic)
    publish(topic, msg);
};

/**
 * Drive the robot with repeated commands at specified intervals.
 * @param topic - The ROS topic to publish to.
 * @param linear - Linear velocities (x, y, z).
 * @param angular - Angular velocities (x, y, z).
 * @param interval - Interval in milliseconds between messages.
 * @param stamped - Whether to publish a TwistStamped message. Defaults to false.
 * @returns A function to cancel the interval timer.
 */
export const driveWithLoop = (
    topic: Topic,
    linear: { x: number; y: number; z: number },
    angular: { o: number; a: number; t: number },
    interval: number,
    stamped: boolean = true
): (() => void) => {
    return accurateIntervalTimer(() => {
        const msg = buildMessage(linear, angular, stamped);
        console.log(topic)
        publish(topic, msg);
    }, interval);
};

/**
 * Build a Twist or TwistStamped message.
 * @param linear - Linear velocities (x, y, z).
 * @param angular - Angular velocities (x, y, z).
 * @param stamped - Whether to include header for TwistStamped. Defaults to false.
 * @returns A ROS message of type Twist or TwistStamped.
 */
export const buildMessage = (
    linear: { x: number; y: number; z: number },
    angular: { o: number; a: number; t: number },
    stamped: boolean = true
): Message => {
    const twist = {
        linear: {
            x: linear.x,
            y: linear.y,
            z: linear.z,
        },
        angular: {
            x: angular.o,
            y: angular.a,
            z: angular.t,
        },
    };

    if (stamped) {
        const now = Date.now();
        const secs = Math.floor(now / 1000);
        const nsecs = (now % 1000) * 1e6;

        return {
            header: {
                // seq: sequenceNumber++,
                stamp: {
                    secs: secs,
                    nsecs: nsecs,
                },
                frame_id: 'dashboard', // Adjust frame_id as needed
            },
            twist: twist,
        };
    } else {
        return twist;
    }
};

/**
 * Stop the robot by publishing zero velocities.
 * @param topic - The ROS topic to publish to.
 * @param stamped - Whether to publish a TwistStamped message. Defaults to false.
 */
export const terminate = (topic: Topic, stamped: boolean = true): void => {
    console.log(topic)
    drive(
        topic,
        { x: 0, y: 0, z: 0 },
        { o: 0, a: 0, t: 0 },
        stamped
    );
};

/**
 * Apply a deadzone to joystick inputs.
 * @param x - X-axis input.
 * @param y - Y-axis input.
 * @param deadzone - Deadzone threshold. Defaults to 0.15.
 * @returns Adjusted [x, y] values after applying deadzone.
 */
export const setDeadzone = (
    x: number,
    y: number,
    deadzone: number = 0.15
): [number, number] => {
    const magnitude = Math.hypot(x, y);

    if (magnitude < deadzone) {
        return [0, 0];
    }

    const scaledMagnitude = ((magnitude - deadzone) / (1 - deadzone));
    const scale = scaledMagnitude / magnitude;

    return [x * scale, y * scale];
};

/**
 * Throttle function calls to a specified time interval.
 * @param callback - The function to throttle.
 * @param delay - Minimum delay in milliseconds between calls.
 * @returns A throttled version of the callback function.
 */
export const throttle = (
    callback: (...args: any[]) => void,
    delay: number
): (...args: any[]) => void => {
    let lastCall = 0;
    let timeoutId: NodeJS.Timeout | null = null;

    return (...args: any[]) => {
        const now = Date.now();

        if (now - lastCall >= delay) {
            lastCall = now;
            callback(...args);
        } else if (!timeoutId) {
            const remainingTime = delay - (now - lastCall);
            timeoutId = setTimeout(() => {
                lastCall = Date.now();
                timeoutId = null;
                callback(...args);
            }, remainingTime);
        }
    };
};

import React, { useState, useEffect } from 'react';
import '../../css/StreamSelector.css'; // Import the CSS file

interface StreamSelectorProps {
  updateStream: (stream: 'front' | 'rear' | 'ptz') => void;
}

export const StreamSelector: React.FC<StreamSelectorProps> = ({ updateStream }) => {
  const [selectedStream, setSelectedStream] = useState<'front' | 'rear' | 'ptz'>('front');

  useEffect(() => {
    // Call updateStream with the default stream on component mount
    updateStream(selectedStream);
  }, [selectedStream, updateStream]);

  const handleButtonClick = (stream: 'front' | 'rear' | 'ptz') => {
    setSelectedStream(stream);
    updateStream(stream);
  };

  return (
    <div>
      <button
        className={selectedStream === 'front' ? 'selected' : ''}
        onClick={() => handleButtonClick('front')}
      >
        Front
      </button>
      <button
        className={selectedStream === 'rear' ? 'selected' : ''}
        onClick={() => handleButtonClick('rear')}
      >
        Rear
      </button>
      <button
        className={selectedStream === 'ptz' ? 'selected' : ''}
        onClick={() => handleButtonClick('ptz')}
      >
        PTZ
      </button>
    </div>
  );
};

export default StreamSelector;

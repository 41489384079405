import React from 'react';

/**
 * Custom React hook for toggling a boolean state.
 * Takes an initial value (default false) and uses a callback so the component is not constantly rerendered.
 */
export const useToggle = (initialVal: boolean = true): [boolean, () => void] => {
    const [ val, setVal ] = React.useState(initialVal);

    const toggle = React.useCallback(() => {
        setVal(v => !v);
    }, []);

    return [val, toggle];
}
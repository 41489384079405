import { FC, memo } from 'react';
import { CircleProgress } from '../../../core/ProgressBars/CircleProgress';
import { useConnection } from '../../../services/Ros/Connection';
import { Stack } from 'react-bootstrap';

//import '../../../css/robot-status.css';

interface RobotVitalProps{
    name : string;
    unit : string;
    progress : number | null | undefined;
    value?: number | null;
}

/**
 * This must be wrapped in a subscriber tag at some point to use useMsg().
 * @param param0 
 * @returns 
 */
const RobotVitalMemo: FC<RobotVitalProps> = ({ name, unit, progress,value=null }) => {

    const connected = useConnection();

    const bar = (progress === -1 || !progress || connected === false) ? 0 : progress as number;
    const text = (progress === -1 || !progress || connected === false) ? '- ' : progress;
    const value_text = (value === -1 || !value || connected === false) ? '-' : Math.round(value*10)/10;


    return(
        <Stack direction='horizontal' gap={2}>
            <span className="vital-text my-auto vital-type">{name}</span>
            <CircleProgress className="ms-2 me-2" progress={bar} strokeWidth={4} circleOneStroke={"#000000"} circleTwoStroke={"#19D0FD"}/>
            {value === null ? <span className="vital-text my-auto vital-progress-text">{`${text}${unit}`}</span> : <span className="vital-text my-auto vital-progress-text">{`${value_text}${unit}`}</span> }
        </Stack>
    );
}

//Export a memoized 
export const RobotVital = memo(RobotVitalMemo);
import React, { useState } from 'react';
import { Message } from 'roslib';
import { Publisher, usePublisher } from '../../services/Ros/Publisher'; // Adjust the import path as needed

// Front Light Button
const FrontLightButton: React.FC = () => {
    const [isFrontLightOn, setIsFrontLightOn] = useState(false); // State for front light
    const publishFrontLight = usePublisher(); // Hook to publish front light messages

    const handleFrontLight = () => {
        const newState = !isFrontLightOn;
        const message: Message = { data: newState }; // Create the ROS message with the new state
        publishFrontLight(message); // Publish the message
        setIsFrontLightOn(newState); // Update the local state
        console.log('Published to /front_light:', newState);
    };

    return (
        
            <button
                onClick={handleFrontLight}
                style={{
                    backgroundColor: isFrontLightOn ? '#007bff' : 'white',
                    color: isFrontLightOn ? 'white' : '#007bff',
                    borderColor: '#0056b3'
                }}
            >
                {isFrontLightOn ? 'Front Light' : 'Front Light'}
            </button>
    );
};

// Rear Light Button
const RearLightButton: React.FC = () => {
    const [isRearLightOn, setIsRearLightOn] = useState(false); // State for rear light
    const publishRearLight = usePublisher(); // Hook to publish rear light messages

    const handleRearLight = () => {
        const newState = !isRearLightOn;
        const message: Message = { data: newState }; // Create the ROS message with the new state
        publishRearLight(message); // Publish the message
        setIsRearLightOn(newState); // Update the local state
        console.log('Published to /rear_light:', newState);
    };

    return (
        
            <button
                onClick={handleRearLight}
                style={{
                    backgroundColor: isRearLightOn ? '#007bff' : 'white',
                    color: isRearLightOn ? 'white' : '#007bff',
                    borderColor: '#0056b3'
                }}
            >
                {isRearLightOn ? 'Rear Light' : 'Rear Light'}
            </button>
    );
};

// Combined Light Control Buttons Component
const LightControlButtons: React.FC = () => {
    return (
        <div>
          <Publisher name="/front_light" type="std_msgs/Bool">
            <FrontLightButton />
          </Publisher>
          <Publisher name="/rear_light" type="std_msgs/Bool">
            <RearLightButton />
          </Publisher>
        </div>
    );
};

export default LightControlButtons;
